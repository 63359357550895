import React from "react";

const CustomLoader = () => {
  return (
    <div
      className="table-loader"
    ></div>
  );
};

export default CustomLoader;