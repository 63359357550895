import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from './userSlice'
import taskSliceReducer from './taskSlice'

import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persisitConfig = {
    key: 'root',
    version: 1,
    storage,
}

const rootReducer = combineReducers({
    user: userReducer,
    myTasks: taskSliceReducer
})

const persistedReducer = persistReducer(persisitConfig, rootReducer)
export const store = configureStore({
    // reducer: {
    //     user: userReducer
    // }
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware({
            serializableCheck : {
                ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        })
})

export const persistor = persistStore(store)

// Storage 
//     -- user
//         -- currentUser, loading, error