import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { assignTaskSchema } from "../../formSchemas";
import HTMLReactParser from "html-react-parser";
import Icon from "../../assets/icons/icon";
import axios from "../../config/axiosinstance";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomLoader from "../common/Loader/CustomLoader";
import ReactSelect from "react-select";

export const customStyles = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isSelected ? "#000" : "#1C1C1C",
    backgroundColor: state.isSelected ? "#ffff" : "#ffff",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 600,
  }),

  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#000",
      fontWeight: 600,
    };
  },

  noOptionsMessage: (provided) => ({
    ...provided,
    color: "#000",
    fontFamily: "Poppins",
    fontWeight: "800",
    fontSize: "14px",
  }),

  control: (defaultStyles) => ({
    ...defaultStyles,
    fontFamily: "Poppins",
    backgroundColor: "#ffff",
    border: "1px solid #155898",
    boxShadow: "none",
    fontSize: "14px",
    borderRadius: "4px",
    padding: "0px 10px 0px 10px",
    minHeight: "40px",
    fontWeight: "500",
    color: "#000",
    "&:hover": {
      border: "1px solid #155898 !important",
    },
  }),
};

const AssignTaskModal = ({
  closeAssignTaskModal,
  tasks,
  setTasks,
  handleLoad,
}) => {
  // console.log("closeAssignTaskModal :-", closeAssignTaskModal)

  // console.log("assignTasks modal :-", tasks)

  const currentUser = useSelector((state) => state.user.currentUser);

  const [members, setMembers] = useState([]);
  const [rewards, setRewards] = useState([]);
  const [rewardPoint, setRewardPoint] = useState("");
  const [strikes, setStrikes] = useState([]);
  const [defaultStrike, setDefaultStrike] = useState();
  const [strikePoint, setStrikePoint] = useState("");
  const [kra_List, setKRAList] = useState([]);

  const [rewardOnlyPoints, setRewardOnlyPoints] = useState("");
  const [strikeOnlyPoints, setStrikeOnlyPoints] = useState("");

  const [default_end_dTime, setDefault_end_dTime] = useState("");

  const fetchDefaultEndDate = useCallback(async () => {
    const body = {
      token: currentUser.token,
      service_name: "task_defult_end_date",
    };

    try {
      const resp = await axios.post("/timesheet-web-api", body);
      console.log("Default End Date: ", resp?.data.data);

      if (resp.data?.status === 1) {
        // toast.success("Task inserted successfully...😊");
        setDefault_end_dTime(
          new Date(resp?.data.data.end_date + " " + resp?.data?.data?.end_time)
        );
        console.log(
          "Default End Date: ",
          resp?.data.data.end_date + " " + resp?.data?.data?.end_time
        );
      }
    } catch (err) {
      console.log("Default End Time error :-", err);
    }
  }, [currentUser.token]);

  useEffect(() => {
    fetchDefaultEndDate();
  }, [fetchDefaultEndDate]);

  const getUserList = async () => {
    try {
      const body = {
        service_name: "get_users",
        token: currentUser?.token,
      };
      const resp = await axios.post("/timesheet-web-api", body);
      console.log('users "-', resp?.data?.data);
      setMembers(resp?.data?.data);
    } catch (error) {
      console.log("error :-", error);
    }
  };

  const [defaultKRAList, setDefaultKRAList] = useState("");

  const getKRAList = async () => {
    try {
      const body = {
        service_name: "get_kra",
        token: currentUser?.token,
      };
      const resp = await axios.post("/timesheet-web-api", body);
      setDefaultKRAList(resp?.data?.default_kra_id?.Kra);
      console.log('KRA "-', resp?.data?.default_kra_id?.Kra);
      setKRAList(resp?.data?.data);
    } catch (error) {
      console.log("error :-", error);
    }
  };

  const [defaultRewardPoints, setDefaultRewardPoints] = useState("");

  const getRewardPointLists = async () => {
    try {
      const body = {
        service_name: "get_reward_points",
        token: currentUser?.token,
      };
      const resp = await axios.post("/timesheet-web-api", body);
      console.log(
        'getRewardPointLists "-',
        resp?.data?.default_reward_point_type?.RewardPointType
      );
      setDefaultRewardPoints(
        resp?.data?.default_reward_point_type?.RewardPointType
      );
      setRewards(resp?.data?.data);
    } catch (error) {
      console.log("error :-", error);
    }
  };

  const getStrikePointLists = async () => {
    try {
      const body = {
        service_name: "get_strike_points",
        token: currentUser?.token,
      };
      const resp = await axios.post("/timesheet-web-api", body);
      console.log('getStrikePointLists "-', resp?.data?.data);
      setStrikes(resp?.data?.data);
      setDefaultStrike(resp?.data?.default_strike_point_type?.StrikePointType);
    } catch (error) {
      console.log("error :-", error);
    }
  };

  useEffect(() => {
    getUserList();
    getRewardPointLists();
    getStrikePointLists();
    getKRAList();
    // eslint-disable-next-line
  }, [currentUser]);

  // const handleRewards = (e) => {
  //   const selectedValue = e.target.value;
  //   try {
  //     const parsedValue = JSON.parse(selectedValue);
  //     console.log("Parsed Value", parsedValue);

  //     setRewardPoint(parsedValue.id);
  //     setRewardOnlyPoints(parsedValue.points);
  //   } catch (error) {
  //     console.error("Error parsing JSON:", error);
  //   }
  // };

  const [isRewardActive, setIsRewardActive] = useState(false);

  const handleRewards = useCallback(
    (e) => {
      if (e === 1) {
        setIsRewardActive(true);
        console.log("DEFAULT: ", defaultRewardPoints);
        // const selectedValue = defaultRewardPoints;
        setRewardOnlyPoints(defaultRewardPoints?.points);
        setRewardPoint(defaultRewardPoints?.id);
        // try {
        //   const parsedValue = selectedValue;
        //   console.log("E TRUE", selectedValue);
        //   setRewardPoint(selectedValue.id);
        //   setRewardOnlyPoints(selectedValue.points);
        // } catch (error) {
        //   console.error("Error parsing JSON:", error);
        // }
      } else {
        setIsRewardActive(false);
        const selectedValue = e.target.value;
        try {
          const parsedValue = JSON.parse(selectedValue);
          console.log("ELSE: ", parsedValue);
          setRewardPoint(parsedValue.id);
          setRewardOnlyPoints(parsedValue.points);
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      }
    },
    [defaultRewardPoints]
  );

  const [isStrikeActive, setIsStrikeActive] = useState(false);

  const handleStrikes = useCallback(
    (e) => {
      if (e === 1) {
        setIsStrikeActive(true);
        const selectedValue = defaultStrike && defaultStrike;
        try {
          const parsedValue = selectedValue;
          console.log("E TRUE", parsedValue);
          setStrikePoint(parsedValue.id);
          setStrikeOnlyPoints(parsedValue.points);
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      } else {
        setIsStrikeActive(false);
        const selectedValue = e.target.value;
        try {
          const parsedValue = JSON.parse(selectedValue);
          console.log("ELSE: ", parsedValue);
          setStrikePoint(parsedValue.id);
          setStrikeOnlyPoints(parsedValue.points);
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      }
    },
    [defaultStrike]
  );

  const [kra_id, setKRA_id] = useState("");

  const handleKRA = (e) => {
    console.log("E", e.target.value);
    setKRA_id(e.target.value);
  };

  // assign_task_list api
  const fetchAssignTask = async () => {
    const body = {
      token: currentUser.token,
      user_id: currentUser.User.id,
      task_status: "Pending",
      service_name: "assigned_task_list",
    };
    try {
      const resp = await axios.post("/timesheet-web-api", body);
      console.log("assign task resp :-", resp);
      setTasks(resp.data?.data);
    } catch (err) {
      console.log("taskList error :-", err);
    }
  };

  // useEffect(() => {
  //     fetchAssignTask()
  // },[])

  const initialValues = {
    // task_id: uuidv4(),
    // user_id: uuidv4(),
    task_title: "",
    task_details: "",
    select_member: "",
    reward_types: "",
    reward_points: "",
    strike_types: "",
    strike_points: "",
    task_end_date: "",
    kra_id: "",
  };

  //   ==== form submit =====
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: assignTaskSchema,
    onSubmit: async (values) => {
      const enteredDateTimestamp = new Date(values.task_end_date).getTime();
      const currentTimestamp = new Date().getTime();

      if (enteredDateTimestamp <= currentTimestamp) {
        setErrMsg("Date & Time Should Be Greater Than Current Date & Time....");

        setTimeout(() => {
          setErrMsg("");
        }, 5000);

        return;
      }
      setIsLoading(true);
      const formattedDate =
        values.task_end_date === ""
          ? formatDate(default_end_dTime)
          : formatDate(values.task_end_date);

      console.log("VALUES", values);
      console.log("re_type :-", values.reward_types);
      values.reward_points = rewardPoint;
      values.strike_points = strikePoint;
      values.kra_id = kra_id;
      // setRewardPoint(values.reward_types)
      console.log(values);

      let parsedStrike = isStrikeActive
        ? strikeOnlyPoints
        : JSON.parse(values.strike_types);
      let parsedRewards = isRewardActive
        ? rewardOnlyPoints
        : JSON.parse(values.reward_types);

      let parsedKRA =
        values.kra_id === "" ? defaultKRAList : JSON.parse(values.kra_id);

      const body = {
        token: currentUser.token,
        service_name: "save_task",
        data: {
          assignee_user_id: values.select_member,
          assigner_user_id: currentUser.User.id,
          created_by: currentUser.User.id,
          end_date: formattedDate,
          id: "",
          title: values.task_title,
          detail: values.task_details,
          reward_point_type_id: values.reward_points,
          reward_points: isRewardActive
            ? rewardOnlyPoints
            : parsedRewards.points,
          strike_point_type_id: values.strike_points,
          strike_points: isStrikeActive
            ? strikeOnlyPoints
            : parsedStrike.points,
          status: "Pending",
          kra_id: parsedKRA?.id,
          my_task: 0,
        },
      };

      try {
        const resp = await axios.post("/timesheet-web-api", body);
        console.log("task resp :-", resp);

        // console.log("values :-", values)

        if (resp.data?.status === 1) {
          resetForm();
          closeAssignTaskModal(false);
          toast.success("Task Assigned successfully...");
          setTimeout(fetchAssignTask, 2000);
          setIsLoading(false);
          handleLoad(true);
        } else {
          console.log("err :-", resp.data.errors);
        }
      } catch (err) {
        setIsLoading(false);
        console.log("save task error :-", err);
        toast.error(err.message);
      }
    },
  });

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const selectOptions =
    members &&
    members.map((option) => ({
      value: option.id,
      label: option.name + "-" + option.id,
    }));

  console.log("rewards", strikes);

  const strikeUpdated =
    strikes &&
    Object.values(strikes).map((item) => ({
      id: item.id,
      name: item.name,
      points: item.points,
    }));

  // {id:1, name: "employee not", points: "14"}

  console.log(
    strikeUpdated,
    rewards,
    strikes?.default_strike_point_type?.StrikePointType
  );

  console.log("POINTS: ", rewardOnlyPoints, rewardPoint);

  useEffect(() => {
    if (!strikeOnlyPoints && !strikePoint) {
      handleStrikes(1);
    }
  }, [strikeOnlyPoints, strikePoint, handleStrikes]);

  useEffect(() => {
    if (!rewardOnlyPoints && !rewardPoint) {
      handleRewards(1);
    }
  }, [rewardOnlyPoints, rewardPoint, handleRewards]);

  console.log("DATA: ", defaultStrike);

  return (
    <div className="addTaskModal custom_modal">
      <div className="custom_modal_backdrop"></div>
      <div className="custom_modal_container">
        <div className="custom_modal_header">
          <h4>assign task to user</h4>
        </div>
        <div className="custom_modal_body">
          <form onSubmit={handleSubmit}>
            <div className="custom_input_field">
              <label>Enter Task Title</label>
              <input
                type="text"
                autoComplete="off"
                name="task_title"
                value={values.task_title}
                onChange={handleChange}
                onBlur={handleBlur}
                id="task_title"
                className="input_field"
              />

              {/* ===== error msg ===== */}
              {errors.task_title && touched.task_title ? (
                <span className="text-danger form_error">
                  {errors.task_title}
                </span>
              ) : null}
            </div>
            <div className="custom_input_field">
              <label>Enter Task Details</label>
              <textarea
                rows={8}
                type="text"
                autoComplete="off"
                name="task_details"
                value={values.task_details}
                onChange={handleChange}
                onBlur={handleBlur}
                id="task_details"
                className="input_field"
              />

              {/* ===== error msg ===== */}
              {errors.task_details && touched.task_details ? (
                <span className="text-danger form_error">
                  {errors.task_details}
                </span>
              ) : null}
            </div>
            <div className="custom_input_field">
              <label className="select-member">Select Member</label>
              {/* <select
                id=""
                className="input_field"
                name="select_member"
                value={values.select_member}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="">Choose member</option>
                {members &&
                  members.map((user) => {
                    return (
                      <option value={user.id} key={user.id}>
                        {user.name}
                      </option>
                    );
                  })}
              </select> */}

              <ReactSelect
                placeholder="Select Members"
                onChange={(option) =>
                  handleChange("select_member")(option.value)
                }
                options={selectOptions}
                styles={customStyles}
              />
              <i className="icon">{HTMLReactParser(Icon.select_input_icon)}</i>

              {errors.select_member && touched.select_member ? (
                <span className="text-danger form_error">
                  {errors.select_member}
                </span>
              ) : null}
            </div>
            <div className="custom_input_field">
              <label>reward types</label>
              <select
                id=""
                className="input_field"
                name="reward_types"
                value={values.reward_types}
                onChange={handleChange}
                onClick={(e) => handleRewards(e)}
                onBlur={handleBlur}
              >
                {" "}
                {/*onChange={handleChange}*/}
                <option value={JSON.stringify(defaultRewardPoints)}>
                  {defaultRewardPoints?.name !== ""
                    ? defaultRewardPoints?.name
                    : "Choose Reward Types"}
                </option>
                {rewards &&
                  rewards.map((reward) => {
                    return (
                      <option value={JSON.stringify(reward)} key={reward.id}>
                        {reward.name}
                      </option>
                    );
                  })}
              </select>
              <i className="icon">{HTMLReactParser(Icon.select_input_icon)}</i>

              {/* {errors.reward_types && touched.reward_types ? (
                <span className="text-danger form_error">
                  {errors.reward_types}
                </span>
              ) : null} */}
            </div>
            <div className="custom_input_field">
              <label>reward points</label>
              <input
                type="text"
                autoComplete="off"
                name="reward_points"
                value={rewardOnlyPoints}
                id=""
                className="input_field"
                disabled
              />
              {/* {
                                errors.reward_points && touched.reward_points ? (
                                    <span className='text-danger form_error'>{errors.reward_points}</span>
                                ) : null
                            } */}
            </div>
            <div className="custom_input_field">
              <label>strike types</label>
              <select
                id=""
                className="input_field"
                name="strike_types"
                value={values.strike_types}
                onChange={handleChange}
                onBlur={handleBlur}
                onClick={(e) => handleStrikes(e)}
              >
                <option value={JSON.stringify(defaultStrike)}>
                  {defaultStrike?.name}
                </option>
                {strikeUpdated &&
                  strikeUpdated.map((strike) => {
                    return (
                      <option value={JSON.stringify(strike)} key={strike.id}>
                        {strike.name}
                      </option>
                    );
                  })}
              </select>
              <i className="icon">{HTMLReactParser(Icon.select_input_icon)}</i>

              {/* {errors.strike_types && touched.strike_types ? (
                <span className="text-danger form_error">
                  {errors.strike_types}
                </span>
              ) : null} */}
            </div>
            <div className="custom_input_field">
              <label>strike points</label>
              <input
                type="text"
                autoComplete="off"
                name="strike_points"
                value={strikeOnlyPoints}
                onChange={handleChange}
                onBlur={handleBlur}
                id=""
                className="input_field"
                disabled
              />
            </div>
            {errMsg && (
              <p className="alert alert-danger err_msg_alert text-center alert-error">
                {errMsg}
              </p>
            )}

            <div className="custom_input_field">
              <label>KRA List</label>
              <select
                id=""
                className="input_field"
                name="kra_list"
                value={kra_id}
                onChange={(e) => handleKRA(e)}
                onClick={(e) => handleKRA(e)}
                onBlur={handleBlur}
              >
                <option value={defaultKRAList?.id}>
                  {defaultKRAList !== "" ? defaultKRAList?.name : "Choose KRA"}
                </option>
                {kra_List &&
                  kra_List.map((kra) => {
                    return (
                      <option value={JSON.stringify(kra)} key={kra.id}>
                        {kra.name}
                      </option>
                    );
                  })}
              </select>
              <i className="icon">{HTMLReactParser(Icon.select_input_icon)}</i>

              {errors.reward_types && touched.reward_types ? (
                <span className="text-danger form_error">
                  {errors.reward_types}
                </span>
              ) : null}
            </div>
            <div className="custom_input_field last_input_field">
              <label>Select Task End Date</label>
              {/* <input type="date" autoComplete='off' name='task_end_date' value={values.task_end_date} onChange={handleChange} onBlur={handleBlur} id="task_details" className='input_field date_input_field' /> */}
              {default_end_dTime !== "" && (
                <DatePicker
                  autoComplete="off"
                  selected={values.task_end_date || new Date(default_end_dTime)}
                  onChange={(date) =>
                    handleChange({
                      target: { name: "task_end_date", value: date },
                    })
                  }
                  onBlur={handleBlur}
                  minDate={new Date()}
                  popperPlacement="top"
                  id="task_details"
                  showTimeInput
                  dateFormat="yyyy-MM-dd HH:mm:ss"
                  className="input_field date_input_field"
                />
              )}
              <span className="icon">
                {HTMLReactParser(Icon.date_input_icon)}
              </span>
              {/* ==== error msg ==== */}
              {errors.task_end_date && touched.task_end_date ? (
                <span className="text-danger form_error">
                  {errors.task_end_date}
                </span>
              ) : null}
            </div>
            <div className="custom_modal_footer">
              <button className="cancel_btn" onClick={closeAssignTaskModal}>
                cancel
              </button>
              <button className="submit_btn" type="submit">
                {isLoading ? <CustomLoader /> : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AssignTaskModal;
