// import HTMLReactParser from "html-react-parser";
import { Rating } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "../../config/axiosinstance";
import toast from "react-hot-toast";
// import Icons from "../../assets/icons/icon";

const RatingModal = ({
  closeRatingModal,
  taskId,
  changedRatingModal,
  handleLoad,
}) => {
  const [feedback_comment, setfeedback_comment] = useState("");
  const [feedback, setfeedback] = useState("");

  const currentUser = useSelector((state) => state.user.currentUser);

  const handleRating = async (e) => {
    e.preventDefault();
    const body = {
      token: currentUser.token,
      service_name: "rating_save",
      data: {
        task_id: taskId,
        feedback: feedback,
        feedback_comment: feedback_comment,
      },
    };

    try {
      const resp = await axios.post("/timesheet-web-api", body);
      console.log("task resp :-", resp);

      // console.log("values :-", values)

      if (resp.data?.status === 1) {
        // closeAssignTaskModal(false);
        toast.success("Thanks! You have rated");
        changedRatingModal(true);
        handleLoad(true);
      } else {
        toast.error(resp.data.msg);
        changedRatingModal(false);
        handleLoad(false);
        console.log("err :-", resp.data.msg);
      }
    } catch (err) {
      console.log("save task error :-", err);
      toast.error(err.message);
      changedRatingModal(false);
      handleLoad(false);
    }
  };
  return (
    <div className="custom_modal delete_modal">
      <div className="custom_modal_backdrop"></div>
      <div className="custom_modal_container">
        <div className="custom_modal_header">
          <h4>
            task ID - <span className="text-danger ms-2">{taskId}</span>
          </h4>
          {/* <p>Task Rating: Strong performance with room for improvement</p> */}
        </div>
        <div className="custom_modal_body pb-0">
          <Rating
            size="2rem"
            name="simple-controlled"
            value={feedback}
            onChange={(event, newValue) => {
              setfeedback(newValue);
            }}
          />

          <form>
            <div className="custom_input_field mb-3 mt-4">
              <label>Enter Comment</label>
              <input
                type="text"
                autoComplete="off"
                name="comment"
                value={feedback_comment}
                onChange={(e) => setfeedback_comment(e.target.value)}
                id="comment"
                className="input_field"
              />
            </div>
            <div className="custom_modal_footer">
              <button className="cancel_btn" onClick={closeRatingModal}>
                cancel
              </button>
              <button
                onClick={(e) => handleRating(e)}
                className="submit_btn"
                type="submit"
              >
                submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RatingModal;
