import HTMLReactParser from 'html-react-parser'
import React, { useState } from 'react'
import Icons from '../../assets/icons/icon'
import { useFormik } from 'formik'
import { confirmPasswordSchema } from '../../formSchemas'
import axios from '../../config/axiosinstance'
import { useSelector } from 'react-redux'

const initialValues = {
  password : "",
}

const ConfirmPasswordModal = ({closePasswordModal, taskId,passwordMatched, setPasswordMatched}) => {

  // console.log("<<< confirm passwordMatched >>>", passwordMatched)

  const currentUser = useSelector(state => state.user.currentUser)
  const [errMsg, setErrMsg] = useState('')

  //   ==== form submit ===== 
  const {values, errors, touched ,handleBlur, handleChange, handleSubmit} = useFormik({
    initialValues,
    validationSchema : confirmPasswordSchema,
    onSubmit: async (values) => {
      // console.log(values)
      const body = {
        service_name : 'Password_check',
        token : currentUser?.token,
        user_id : currentUser?.User?.id,
        pass : values.password
      }
      try{
        const resp = await axios.post("/timesheet-web-api", body)
        // console.log("approve request task resp :-", resp)
        const serverResp = resp?.data
        // console.log("<<<< serverResp >>>>>", serverResp)
        if(serverResp.status === 1){
          setErrMsg('')
          setPasswordMatched(true)
          closePasswordModal(false)
        }else{
          setErrMsg(serverResp.msg)
        }
      }catch(err){
        setErrMsg(err)
      }
    }
  });

  return (
    <div className='custom_modal delete_modal'>
        <div className="custom_modal_backdrop"></div>
        <div className="custom_modal_container">
            <div className="custom_modal_header">
              <h4>task ID - <span className='text-danger ms-2'>{taskId}</span></h4>
            </div>
            <div className="custom_modal_body pb-0">
                <i>{HTMLReactParser(Icons.lock_icon)}</i>
                <h4 className='mb-0'>Enter password</h4>
                <p>Confirm Your Password Before Move</p>
                {
                  errMsg && (
                    <div className='alert alert-danger alert-sm mt-2 py-2 text-sm'>{errMsg}</div>
                  )
                }
                <form onSubmit={handleSubmit} className='mt-4'>
                  <div className="custom_input_field mb-3">
                        <label>Enter password</label>
                        <input type="text" autoComplete='off' name='password' value={values.password} onChange={handleChange} onBlur={handleBlur} id="password" className='input_field'/>

                        {/* ===== error msg ===== */}
                        {
                            errors.password && touched.password ? (
                                <span className='text-danger form_error'>{errors.password}</span>
                            ) : null 
                        }
                    </div>
                    <div className="custom_modal_footer">
                        <button className='cancel_btn' onClick={closePasswordModal}>cancel</button>
                        <button className='submit_btn' type='submit'>submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
  )
}

export default ConfirmPasswordModal
