import React, { useEffect, useState } from "react";
import DeletedTaskTable from "../../../components/table/DeletedTaskTable";
import FilterHeader from "../../../components/taskManager/FilterHeader";
import { useSelector } from "react-redux";
import axios from "../../../config/axiosinstance";
import MetaTitle from "../../../components/MetaTitle/MetaTitle";

const DeletedTask = () => {
  const currentUser = useSelector((state) => state.user.currentUser);

  const [deletedTask, setDeletedTask] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [valSearch, setValSearch] = useState("");

  const fetchDeletedTask = async (value) => {
    setIsLoading(true);
    const body = {
      token: currentUser.token,
      assigner_user_id: currentUser?.User.id,
      service_name: "deleted_task_list",
      search_task_id: value,
    };
    try {
      const resp = await axios.post("/timesheet-web-api", body);
      // console.log("deleted task resp :-", resp)
      if (resp?.data?.status === 1) {
        setIsLoading(false);
        setDeletedTask(resp.data?.data);
      }
    } catch (err) {
      setIsLoading(false);
      console.log("taskList error :-", err);
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchDeletedTask();
    } else {
      console.log("no users found...");
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [currentUser]);

  // console.log("deletedTask :-", deletedTask)

  const getValue = (val) => {
    setValSearch(val?.id);
    fetchDeletedTask(val?.id);
    // handleFetchMyTask(val?.id);
    console.log("PROPS: ", val.value);
  };

  const resetTask = () => {
    fetchDeletedTask();
  };

  console.log(valSearch);

  return (
    <div className="deletedTask_page">
      <MetaTitle title={`Deleted Task Details`} />
      {currentUser ? (
        <>
          <FilterHeader
            addTaskBtn="false"
            getValue={getValue}
            deletedTask={true}
            assignTaskBtn="false"
            resetTask={resetTask}
          />
          <DeletedTaskTable
            deletedTask={deletedTask}
            setDeletedTask={setDeletedTask}
            isLoading={isLoading}
          />
        </>
      ) : (
        <h1 className="text-center py-4 text-capitalize d-flex align-items-center justify-content-center">
          user not authenticated please login first
          {/* loading... */}
        </h1>
      )}
    </div>
  );
};

export default DeletedTask;
