import React, { useState } from "react";
import HTMLReactParser from "html-react-parser";
import Icon from "../../assets/icons/icon";
import AddTask from "./AddTask";
import AssignTaskModal from "./AssignTaskModal";
import ReactSelect from "react-select";
import axios from "../../config/axiosinstance";
import { useSelector } from "react-redux";
import CrossIcon from "../../assets/icons/cross.png";

export const customStyles = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isSelected ? "#000" : "#1C1C1C",
    backgroundColor: state.isSelected ? "#ffff" : "#ffff",
    fontFamily: "Poppins",
    fontSize: "14px",
  }),

  noOptionsMessage: (provided) => ({
    ...provided,
    color: "#000",
    fontFamily: "Poppins",
    fontSize: "14px",
  }),

  control: (defaultStyles) => ({
    ...defaultStyles,
    fontFamily: "Poppins",
    backgroundColor: "#ffff",
    border: "none",
    boxShadow: "none",
    fontSize: "14px",
    borderRadius: "7px",
    padding: "0px 10px 0px 10px",
    minHeight: "40px",
  }),
};

const FilterHeader = ({
  addTaskBtn,
  assignTaskBtn,
  tasks,
  setTasks,
  addTaskSubmit,
  setAddTaskSubmit,
  handleLoad,
  SelfTask,
  getValue,
  assignTask,
  masterTask,
  id,
  hideSearch,
  hideDate,
  deletedTask,
  resetTask,
  extension,
}) => {
  //   const [searchOpen, setSearchOpen] = useState(false);
  //   const [dateFilterOpen, setDateFilterOpen] = useState(false);
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);
  const [showAssignTaskModal, setShowAssignTaskModal] = useState(false);

  const closeModal = () => {
    setShowAddTaskModal(false);
    document.body.style.overflow = "auto";
  };

  const closeAssignTaskModal = () => {
    setShowAssignTaskModal(false);
    document.body.style.overflow = "auto";
  };

  // console.log("addTaskBtn :-", addTaskBtn)
  // console.log("assignTaskBtn :-", assignTaskBtn)

  // const options = [
  //   { value: "chocolate", label: "Chocolate" },
  //   { value: "strawberry", label: "Strawberry" },
  //   { value: "vanilla", label: "Vanilla" },
  // ];

  const [selectedOption, setSelectedOption] = useState(null);

  const currentUser = useSelector((state) => state.user.currentUser);

  const [options, setOptions] = useState([]);

  const fetchData = async (inputValue, i, x) => {
    console.log("INPUT: ", i);
    const body = {
      service_name:
        i === true && !x
          ? "assigned_task_list_auto_suggestion"
          : i === "master"
          ? "master_task_list_auto_suggestion"
          : i === "self"
          ? "myself_task_list_auto_suggestion"
          : i === true && x === "delete"
          ? "deleted_task_list_auto_suggestion"
          : i === "extension"
          ? "task_date_extension_list_auto_suggestion"
          : "task_list_auto_suggestion",
      token: currentUser?.token,
      search_task_title: inputValue,
    };

    if (i === true && i !== "extension") {
      body.assigner_user_id = currentUser.User.id;
    } else if (i === "extension") {
      body.user_id = currentUser.User.id;
    } else {
      body.assignee_user_id =
        id !== null && id !== undefined && id !== null
          ? id
          : currentUser.User.id;
    }
    try {
      const response = await axios.post("/timesheet-web-api", body);
      console.log("SELECT: ", response?.data?.data);
      const data = response?.data?.data;

      // if (i === "extension") {
      //   const updatedD =
      //     data &&
      //     data?.map((item) => ({
      //       id: item.Task.id,
      //       label: item["0"].search_title,
      //       value: item["0"].search_title,
      //     }));
      //   setOptions(updatedD);
      // } else {
      let updateData =
        data &&
        data?.map((item) => ({
          id: item.Task.id,
          label: item.Task.search_title,
          value: item.Task.search_title,
        }));
      setOptions(updateData);
      // }

      // console.log("DATA", updateData, updatedD);
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const [isSelected, setIsSelected] = useState(false);

  const handleInputChange = (inputValue) => {
    // console.log("INPUTS: ", inputValue);
    setSelectedOption(inputValue);
    if (inputValue.length >= 3) {
      if (assignTask === true) {
        fetchData(inputValue, true);
      } else {
        fetchData(inputValue, false);
      }
      if (masterTask === true) {
        fetchData(inputValue, "master");
      }
      if (SelfTask === true) {
        fetchData(inputValue, "self");
      }
      if (deletedTask === true) {
        fetchData(inputValue, true, "delete");
      }
      if (extension === true) {
        fetchData(inputValue, "extension");
      }
      // setOptions(data);
    } else {
      setOptions([]);
    }
  };

  const handleSelected = (i) => {
    setIsSelected(true);
    setSelectedOption(i);
    getValue(i);
  };

  console.log("DATA: ", assignTask, masterTask);
  console.log("NEW ID: ", id);

  const handleReset = () => {
    setIsSelected(false);
    resetTask();
  };

  console.log("SELECTED OPTIONS: ", selectedOption);

  return (
    <div className="filter_container max_container">
      {hideSearch || hideDate ? null : (
        <div className="header_actions">
          <div
            className="menu search_menu"
            style={{
              maxWidth:
                // addTaskBtn === "false" || assignTaskBtn === "false"
                "100%",
              // : "",
            }}
          >
            <div style={{ position: "relative", top: 0 }}>
              <ReactSelect
                // value={selectedOption}
                placeholder={
                  selectedOption !== "" ? selectedOption : "Searching..."
                }
                onInputChange={(inputValue) => handleInputChange(inputValue)}
                options={options}
                styles={customStyles}
                onChange={(selectedOption) => handleSelected(selectedOption)}
                // menuIsOpen={true}
              />
            </div>

            {isSelected && (
              <div className="cross-icon-container">
                <button
                  onClick={() => handleReset()}
                  style={{
                    padding: "0px 40px",
                    backgroundColor: "transparent",
                  }}
                >
                  <img className="cross-icon" src={CrossIcon} alt="" />
                </button>
              </div>
            )}
          </div>

          {/* <div className="menu date_filter_menu">
            <ReactSelect
              placeholder="Choose Date"
              styles={customStyles}
            />
          </div> */}

          {addTaskBtn === "true" && (
            <button
              className="custom_btn"
              onClick={() => {
                setShowAddTaskModal(true);
                document.body.style.overflow = "hidden";
              }}
              style={{ maxWidth: "15%" }}
            >
              {HTMLReactParser(Icon.plus_icon)}
              add task
            </button>
          )}

          {assignTaskBtn === "true" && (
            <button
              className="custom_btn"
              onClick={() => {
                setShowAssignTaskModal(true);
                document.body.style.overflow = "hidden";
              }}
              style={{ maxWidth: "20%" }}
            >
              {HTMLReactParser(Icon.plus_icon)}
              Assign task to user
            </button>
          )}
        </div>
      )}

      {/* ===== addTask modal ==== */}
      {showAddTaskModal && (
        <AddTask
          closeModal={closeModal}
          tasks={tasks}
          setTasks={setTasks}
          addTaskSubmit={addTaskSubmit}
          setAddTaskSubmit={setAddTaskSubmit}
          handleLoad={handleLoad}
          SelfTask={SelfTask}
        />
      )}

      {/* ====== assign task to user modal ========== */}
      {showAssignTaskModal && (
        <AssignTaskModal
          closeAssignTaskModal={closeAssignTaskModal}
          tasks={tasks}
          setTasks={setTasks}
          handleLoad={handleLoad}
        />
      )}
    </div>
  );
};

export default FilterHeader;
