import HTMLReactParser from "html-react-parser";
import React, { useState } from "react";
import Icon from "../../assets/icons/icon";
import RequestExtensionModal from "../taskManager/RequestExtensionModal";
import ApproveRequestModal from "../taskManager/ApproveRequestModal";
import AnimatedLoader from "../common/Loader/AnimatedLoader";

const RequestExtensionTable = ({ requestApprovalTask, isLoading }) => {
  //   console.log("requestApprovalTask :-", requestApprovalTask)
  const [showApproveRequest, setShowApproveRequest] = useState(false);
  const [showDeclineRequest, setShowDeclineRequest] = useState(false);
  const [showEmbededRequest, setShowEmbededRequest] = useState(false);
  const [reqExtension, setRequestExtension] = useState({});
  const [taskItem, setTaskItem] = useState({});

  const closeApproveRequestModal = () => setShowApproveRequest(false);
  const closeDeclineRequestModal = () => setShowDeclineRequest(false);
  const closeEmbededRequestModal = () => setShowEmbededRequest(false);

  // custom pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 10;
  const maxVisiblePages = 5; // Set the maximum number of visible pages here
  const lastIndex = currentPage * recordPerPage;
  const firstIndex = lastIndex - recordPerPage;
  const records = requestApprovalTask.slice(firstIndex, lastIndex);
  const npage = Math.ceil(requestApprovalTask.length / recordPerPage);
  const totalVisiblePages = Math.min(maxVisiblePages, npage); // Calculate the total number of visible pages
  const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2)); // Calculate the starting page

  const numbers = Array.from(
    { length: totalVisiblePages },
    (_, index) => startPage + index
  );

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const changeCPage = (pageNo) => {
    setCurrentPage(pageNo);
  };

  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  //    ============ embeded request =================
  const embedRequestModal = (taskRequest) => {
    console.log("selected task row :-", taskRequest);
    if (taskRequest) {
      setShowEmbededRequest(true);
      setTaskItem(taskRequest);
    } else {
      alert("something went wrong...");
    }
  };

  //   ========== approve request ===========
  const approveRequestModal = (taskRequest) => {
    console.log("selected task row :-", taskRequest);
    if (taskRequest) {
      setShowApproveRequest(true);
      setRequestExtension({
        taskId: taskRequest?.task_id,
        title: "approve",
        msg: "Are you sure to Approve Extension request?",
        data: taskRequest,
      });
    } else {
      alert("something went wrong...");
    }
  };

  //   ============= declined request =================
  const declineRequestModal = (taskRequest) => {
    console.log("selectedItem :-", taskRequest);
    if (taskRequest) {
      setShowDeclineRequest(true);
      setRequestExtension({
        taskId: taskRequest?.task_id,
        title: "decline",
        msg: "Are you sure to Decline Extension Request?",
        data: taskRequest,
      });
    } else {
      alert("something went wrong...");
    }
  };

  return (
    <div className="table_card request_extension_table_card">
      <table>
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>ID</th>
            <th style={{ textAlign: "left" }}>Title</th>
            <th style={{ textAlign: "left" }}>Request by</th>
            <th style={{ textAlign: "left" }}>Reason</th>
            <th width={100} style={{ textAlign: "left" }}>
              Request end date
            </th>
            <th style={{ textAlign: "left" }}>Create at</th>
            <th style={{ textAlign: "left" }}>Approve by</th>
            <th style={{ textAlign: "left" }}>Approve end date</th>
            <th style={{ textAlign: "left" }}>Approve Status</th>
            <th>Action</th>
          </tr>
        </thead>
        {isLoading ? (
          <caption className="text-capitalize text-center text-lg pt-4">
            <AnimatedLoader />
          </caption>
        ) : (
          <>
            {requestApprovalTask.length > 0 ? (
              <tbody>
                <>
                  {records.map((item, index) => {
                    return (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>{item.task_id}</td>
                        <td style={{ textAlign: "left" }}>{item.task_title}</td>
                        <td style={{ textAlign: "left" }}>
                          {item.request_by_name}
                        </td>
                        <td style={{ textAlign: "left" }}>{item.reason}</td>
                        <td style={{ textAlign: "left" }}>
                          {item.request_end_date}
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {item.created_date}
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {item.approved_by}
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {item.approve_end_date}
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {item.approval_status_text}
                        </td>
                        <td className="request_action">
                          {item.approval_status === 4 ? null : (
                            <>
                              {item.approval_status === "0" && (
                                <>
                                  <button
                                    className="approve_btn"
                                    onClick={() => approveRequestModal(item)}
                                  >
                                    Approve
                                  </button>
                                  <button
                                    className="decline_btn"
                                    onClick={() => declineRequestModal(item)}
                                  >
                                    Decline
                                  </button>
                                  <button
                                    className="embed_btn"
                                    onClick={() => embedRequestModal(item)}
                                  >
                                    Embeded
                                  </button>
                                </>
                              )}
                              {item.approval_status_text === "Hold" && (
                                <>
                                  <button
                                    className="embed_btn"
                                    onClick={() => embedRequestModal(item)}
                                  >
                                    Embeded
                                  </button>
                                </>
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </>
              </tbody>
            ) : (
              <caption className="text-danger text-capitalize text-center pt-4">
                No Data Found
              </caption>
            )}
          </>
        )}
      </table>

      {/* ====== pagination ===== */}
      {numbers.length > 0 && (
        <div className="custom_pagination_container">
          <ul className="custom_pagination">
            <li className="pagination_item prev">
              <button className="pagination_btn" onClick={prevPage}>
                {HTMLReactParser(Icon.prev_icon)}
              </button>
            </li>

            {numbers.map((n, i) => (
              <li
                className={`pagination_item ${
                  currentPage === n ? "active" : ""
                }`}
                key={i}
                onClick={() => changeCPage(n)}
              >
                <span className="pagination_link">{n}</span>
              </li>
            ))}

            <li className="pagination_item next">
              <button className="pagination_btn" onClick={nextPage}>
                {HTMLReactParser(Icon.next_icon)}
              </button>
            </li>
          </ul>
        </div>
      )}

      {showApproveRequest && (
        <RequestExtensionModal
          reqExtension={reqExtension}
          closeApproveRequestModal={closeApproveRequestModal}
        />
      )}

      {showEmbededRequest && (
        <ApproveRequestModal
          task={taskItem}
          closeEmbededRequestModal={closeEmbededRequestModal}
        />
      )}

      {showDeclineRequest && (
        <RequestExtensionModal
          reqExtension={reqExtension}
          closeDeclineRequestModal={closeDeclineRequestModal}
        />
      )}
    </div>
  );
};

export default RequestExtensionTable;
