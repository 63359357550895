import React, { useCallback, useEffect, useState } from "react";
import Icon from "../../assets/icons/icon";
import HTMLReactParser from "html-react-parser";
import axios from "axios";
import { useSelector } from "react-redux";
import TaskModal from "../taskManager/TaskModal";

const PendingTodoRecords = ({ ToDOrecords, isLoading }) => {
  // console.log("<<< ToDOrecords >>>", ToDOrecords)

  const currentUser = useSelector((state) => state.user.currentUser);

  const [currentPage, setCurrentPage] = useState(1);

  const recordPerPage = 5;
  const lastIndex = currentPage * recordPerPage;
  const firstIndex = lastIndex - recordPerPage;
  const records = ToDOrecords.slice(firstIndex, lastIndex);
  const npage = Math.ceil(ToDOrecords.length / recordPerPage);

  const numbers = [...Array(npage + 1).keys()].slice(1);

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const changeCPage = (pageNo) => {
    setCurrentPage(pageNo);
  };

  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const [userList, setUserList] = useState([]);

  const getUserList = useCallback(async () => {
    try {
      const body = {
        service_name: "get_users",
        token: currentUser?.token,
      };
      const resp = await axios.post("/timesheet-web-api", body);
      console.log('users "-', resp?.data?.data);
      setUserList(resp?.data?.data);
    } catch (error) {
      console.log("error :-", error);
    }
  }, [currentUser?.token]);

  useEffect(() => {
    getUserList();
  }, [getUserList]);

  console.log("USERS: ", userList);

  console.log(ToDOrecords, "records");

  const [showModal, setShowModal] = useState(false);
  const [taskId, setTaskId] = useState();

  const showTaskModal = (i) => {
    setShowModal(true);
    setTaskId(i);
  };

  const closeTaskModal = () => setShowModal(false);

  return (
    <div className="table_card px-3 py-3">
      <table>
        <thead>
          <tr>
            <th>Id</th>
            <th>Assigner</th>
            <th>Title</th>
            {/* <th>Detail</th> */}
            <th>Created Date</th>
            {/* <th>Start Date</th> */}
            {/* <th>End Date</th> */}
            <th>Comment</th>
            <th>Commented By</th>
            <th>Comment Date</th>
            <th>Actions</th>
            {/* <th>Status</th> */}
          </tr>
        </thead>
        {isLoading ? (
          // <h1 className='text-center py-4 text-sm'>Loading...</h1>
          <caption className="text-capitalize text-center text-lg pt-4">
            loading...
          </caption>
        ) : (
          <>
            {ToDOrecords.length > 0 ? (
              <tbody>
                {records.map((data, index) => {
                  return (
                    <tr
                      style={{
                        backgroundColor: data.color === "1" ? "#E72929" : "",
                      }}
                      key={data.id}
                    >
                      <td
                        style={{
                          color: data.color === "1" ? "white" : "",
                          borderTopLeftRadius: data.color === "1" ? "10px" : "",
                          borderBottomLeftRadius:
                            data.color === "1" ? "10px" : "",
                        }}
                      >
                        {data.id}
                      </td>
                      <td style={{ color: data.color === "1" ? "white" : "" }}>
                        {data.AssignerUser.firstname +
                          " " +
                          data.AssignerUser.lastname}
                      </td>
                      <td style={{ color: data.color === "1" ? "white" : "" }}>
                        {data.title}
                      </td>
                      {/* <td>{data.detail}</td> */}
                      <td style={{ color: data.color === "1" ? "white" : "" }}>
                        {data.CreatedDate}
                      </td>
                      <td style={{ color: data.color === "1" ? "white" : "" }}>
                        {data.Lastcomment.comment}
                      </td>
                      <td style={{ color: data.color === "1" ? "white" : "" }}>
                        {data?.Lastcomment?.created_name}
                      </td>
                      <td style={{ color: data.color === "1" ? "white" : "" }}>
                        {data.Lastcomment.created}
                      </td>
                      <td
                        style={{
                          color: data.color === "1" ? "white" : "",
                          borderTopRightRadius:
                            data.color === "1" ? "10px" : "",
                          borderBottomRightRadius:
                            data.color === "1" ? "10px" : "",
                        }}
                      >
                        <button
                          className="view-cta-pending"
                          onClick={() => showTaskModal(data.id)}
                        >
                          View
                        </button>
                      </td>
                      {/* <td>{data.StartDate}</td>
                      <td>{data.EndDate}</td>
                      <td>{data.completedate}</td>
                      <td>{data.task_status}</td> */}
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <caption className="text-danger text-capitalize text-center pt-4">
                No Records Found
              </caption>
            )}
          </>
        )}
      </table>

      {/* ====== pagination ===== */}
      {numbers.length > 0 && (
        <div className="custom_pagination_container">
          <ul className="custom_pagination">
            <li className="pagination_item prev">
              <button className="pagination_btn" onClick={prevPage}>
                {HTMLReactParser(Icon.prev_icon)}
              </button>
            </li>

            {numbers.map((n, i) => {
              return (
                <li
                  className={`pagination_item ${
                    currentPage === n ? "active" : ""
                  }`}
                  key={i}
                  onClick={() => changeCPage(n)}
                >
                  <span className="pagination_link">{n}</span>
                </li>
              );
            })}

            <li className="pagination_item next">
              <button className="pagination_btn" onClick={nextPage}>
                {HTMLReactParser(Icon.next_icon)}
              </button>
            </li>
          </ul>
        </div>
      )}

      {showModal && (
        <TaskModal taskId={taskId} closeTaskModal={closeTaskModal} />
      )}
    </div>
  );
};

export default PendingTodoRecords;
