import { useFormik } from "formik";
import React, { useState } from "react";
import { approveRequestTaskSchema } from "../../formSchemas";
import Icon from "../../assets/icons/icon";
import HTMLReactParser from "html-react-parser";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import axios from "../../config/axiosinstance";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ApproveRequestModal = ({ closeEmbededRequestModal, task }) => {
  console.log("<<<< embeded task >>>>", task);

  const currentUser = useSelector((state) => state.user.currentUser);

  const [errMsg, setErrMsg] = useState("");

  const initialValues = {
    // task_id : task.task_id,
    // task_title : task.task_title,
    // request_by : task.request_by,
    // request_end_date : task.request_end_date,
    // request_end_time : task.request_end_time,
    // reason : task.reason,
    remarks: "",
    task_date: "",
  };

  //   ==== form submit =====
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: approveRequestTaskSchema,
      onSubmit: async (values) => {
        // console.log("DATE: ", values.task_date);
        // console.log("DATE1: ", task?.end_date);
        // const formattedDate = formatDate(values.task_date);
        // const enteredDateTime = new Date(values.task_date);
        // const currentTime = new Date(task?.end_date);
        // const enteredTime =
        //   enteredDateTime.getHours() * 3600 +
        //   enteredDateTime.getMinutes() * 60 +
        //   enteredDateTime.getSeconds();
        // const currentTimes =
        //   currentTime.getHours() * 3600 +
        //   currentTime.getMinutes() * 60 +
        //   currentTime.getSeconds();

        // if (enteredTime <= currentTimes) {
        //   setErrMsg("Time should be greater than the Task End Time.");

        //   setTimeout(() => {
        //     setErrMsg("");
        //   }, 5000);

        //   return;
        // }

        const formattedDate = formatDate(values.task_date);
        const enteredDateTime = new Date(values.task_date);
        const currentTime = new Date(task?.end_date);

        // Check if entered time is less than current time
        if (enteredDateTime <= currentTime) {
          setErrMsg("Time should be greater than the Task End Time.");
          setTimeout(() => {
            setErrMsg("");
          }, 5000);
          return;
        }
        // console.log(values)
        const body = {
          service_name: "task_date_extension_embeded",
          token: currentUser?.token,
          data: {
            task_id: task?.task_id,
            request_extension_id: task?.id,
            approved_by: currentUser?.User?.id,
            approved_remarks: values.remarks,
            approve_end_date: formattedDate,
          },
        };
        // console.log("<<< body >>>", body)
        try {
          const resp = await axios.post("/timesheet-web-api", body);
          // console.log("approve request task resp :-", resp)
          const serverResp = resp?.data;
          console.log("<<<< serverResp >>>>>", serverResp);
          if (serverResp.status === 1) {
            closeEmbededRequestModal();
            toast.success(serverResp.msg);
          } else {
            // toast.error(serverResp.msg)
            setErrMsg(serverResp.msg);
          }
        } catch (err) {
          closeEmbededRequestModal();
          toast.error(err);
        }
      },
    });

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  console.log("TASKS: ", task?.end_date);
  return (
    <div className="custom_modal approveRequestModal">
      <div className="custom_modal_backdrop"></div>
      <div className="custom_modal_container">
        <div className="custom_modal_header">
          <h4>Approve request</h4>
        </div>
        <div className="custom_modal_body">
          <form onSubmit={handleSubmit}>
            <div className="task_details_form">
              <table>
                <tbody>
                  <tr>
                    <th>task title : </th>
                    <td>{task.task_title}</td>
                  </tr>
                  <tr>
                    <th>request by : </th>
                    <td>{task.request_by}</td>
                  </tr>
                  <tr>
                    <th>request end date : </th>
                    <td>{task.request_end_date}</td>
                  </tr>
                  <tr>
                    <th>reason : </th>
                    <td>{task.reason}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {errMsg && (
              <p className="alert alert-danger err_msg_alert my-2">{errMsg}</p>
            )}
            <div className="custom_input_field">
              <label>Approve remarks</label>
              <textarea
                rows={8}
                type="text"
                autoComplete="off"
                name="remarks"
                value={values.remarks}
                onChange={handleChange}
                onBlur={handleBlur}
                id=""
                className="input_field"
              />

              {/* ===== error msg ===== */}
              {errors.remarks && touched.remarks ? (
                <span className="text-danger form_error">{errors.remarks}</span>
              ) : null}
            </div>
            <div className="custom_input_field last_input_field">
              <label>enter Task Date</label>
              {/* <input type="date" autoComplete='off' name='task_date' value={values.task_date} onChange={handleChange} onBlur={handleBlur} id="" className='input_field date_input_field'/> */}

              <DatePicker
                autoComplete="off"
                selected={values.task_date || null}
                onChange={(date) =>
                  handleChange({
                    target: { name: "task_date", value: date },
                  })
                }
                onBlur={handleBlur}
                id="task_details"
                showTimeInput
                popperPlacement="top"
                dateFormat="yyyy-MM-dd HH:mm:ss"
                className="input_field date_input_field"
                minDate={new Date(task?.end_date)}
              />

              <span className="icon">
                {HTMLReactParser(Icon.date_input_icon)}
              </span>
              {/* ==== error msg ==== */}
              {errors.task_date && touched.task_date ? (
                <span className="text-danger form_error">
                  {errors.task_date}
                </span>
              ) : null}
            </div>
            <div className="custom_modal_footer">
              {errMsg ? (
                <button
                  className="cancel_btn secondary"
                  onClick={closeEmbededRequestModal}
                >
                  cancel
                </button>
              ) : (
                <>
                  <button
                    className="cancel_btn"
                    onClick={closeEmbededRequestModal}
                  >
                    cancel
                  </button>
                  <button className="submit_btn" type="submit">
                    approve
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ApproveRequestModal;
