import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "../../config/axiosinstance";
import toast from "react-hot-toast";
import CrossIcon from "../../assets/icons/cross.png";
// import CustomLoader from "../common/Loader/CustomLoader";

const TaskModal = ({ taskId, closeTaskModal }) => {
  const currentUser = useSelector((state) => state.user.currentUser);

  const [taskList, setTaskList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const getTaskList = useCallback(async () => {
    setIsLoading(true);
    try {
      const body = {
        service_name: "get_task_conversation",
        token: currentUser?.token,
        task_id: taskId,
      };
      const resp = await axios.post("/timesheet-web-api", body);
      console.log('Task List Data "-', resp?.data);
      if (resp?.data?.status === 1) {
        // Convert to array of objects
        const task_data_arr = resp?.data?.data?.map((item) => ({
          id: item.TaskComment.id,
          is_group_chat: item.TaskComment.is_group_chat,
          task_id: item.TaskComment.task_id,
          user_id: item.TaskComment.user_id,
          type: item.TaskComment.type,
          comment: item.TaskComment.comment,
          file: item.TaskComment.file,
          created: item.TaskComment.created,
          created_by: item.TaskComment.created_by,
          user_name: item.User.name.trim(),
        }));
        setTaskList(task_data_arr);
        setIsLoading(false);
      } else if (resp?.data?.status === 0) {
        toast.error(resp?.data?.msg);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error :-", error);
    }
  }, [currentUser?.token, taskId]);

  useEffect(() => {
    getTaskList();
  }, [getTaskList]);

  return (
    <div className="custom_modal approve_req_modal">
      <div className="custom_modal_backdrop"></div>
      <div className="custom_modal_container" style={{ maxWidth: "80%" }}>
        <div className="custom_modal_header header-task-modal">
          <h4 className="text-dark">
            ID - <span className="text-danger ms-2">{taskId}</span>
          </h4>
          <button onClick={closeTaskModal}>
            <img src={CrossIcon} alt="" />
          </button>
        </div>
        <div className="custom_modal_body">
          <div className="table_card px-3 py-3">
            <table>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Comment</th>
                  <th>User Name</th>
                  <th>Created Date</th>
                </tr>
              </thead>
              {isLoading ? (
                <caption className="text-capitalize text-center text-lg pt-4">
                  loading...
                </caption>
              ) : (
                <>
                  {taskList && taskList?.length > 0 ? (
                    <tbody>
                      {taskList &&
                        taskList.map((data, index) => {
                          return (
                            <tr key={data.id}>
                              <td>{data.task_id}</td>
                              <td>{data.comment}</td>
                              <td>{data.user_name}</td>
                              <td>{data.created}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  ) : (
                    <caption className="text-danger text-capitalize text-center pt-4">
                      No Records Found
                    </caption>
                  )}
                </>
              )}
            </table>
          </div>
        </div>
        {/* <div className="custom_modal_footer">
          {closeTaskModal && (
            <button className="view-cta-pending" onClick={closeTaskModal}>
              OK
            </button>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default TaskModal;
