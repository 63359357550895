import React from "react";
import "./taskHeader.scss";
import { NavLink } from "react-router-dom";
import {
  MdPersonOutline,
  MdOutlineTask,
  MdOutlinePersonAddAlt,
  MdOutlineDeleteForever,
  MdAccessTime,
  MdSwapVert,
  MdSelfImprovement,
} from "react-icons/md";

const TaskHeader = () => {
  return (
    <div className="task_header max_container">
      <nav>
        <ul>
          <li>
            <NavLink to="my-task" className="nav-link">
              <MdPersonOutline />
              my task <br /> board
            </NavLink>
          </li>
          <li>
            <NavLink to="assign-task" className="nav-link">
              <MdOutlineTask />
              Assigned <br /> Task Board
            </NavLink>
          </li>
          <li>
            <NavLink to="master-task-board" className="nav-link">
              <MdOutlinePersonAddAlt />
              Master <br /> Task Board
            </NavLink>
          </li>
          <li>
            <NavLink to="deleted-task" className="nav-link">
              <MdOutlineDeleteForever />
              Deleted <br /> Task Details
            </NavLink>
          </li>
          <li>
            <NavLink to="pending-task" className="nav-link">
              <MdAccessTime />
              Pending <br /> Task Activities
            </NavLink>
          </li>
          <li>
            <NavLink to="request-extension" className="nav-link">
              <MdSwapVert />
              Request Extension <br /> Pending Approval
            </NavLink>
          </li>
          <li>
            <NavLink to="self-task" className="nav-link">
              <MdSelfImprovement />
              Self Task
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default TaskHeader;
