import React, { useEffect, useState } from "react";
import Icon from "../../assets/icons/icon";
import HTMLReactParser from "html-react-parser";
import ChatBody from "../chat/ChatBody";
import DeleteTaskModal from "./DeleteTaskModal";
import ExtensionTaskModal from "./ExtensionTaskModal";
import { toast } from "react-hot-toast";
import { useDrag } from "react-dnd";
import { DEFAULT_IMG } from "../../DefaultImg";
import { Rating, Tooltip } from "@mui/material";
// import { useSelector } from "react-redux";
// import axios from "../../config/axiosinstance";

const TaskComponent = ({
  task,
  border_color,
  dragBg_color,
  tasks,
  assign_Task,
  setTasks,
  isCurrent,
  pending,
  Inprogress,
  UnderReview,
  Complete,
  expired,
  hold,
  handleLoad,
  SelfTask,
  Iscomplete,
}) => {
  const [showDeleteTaskModal, setShowDeleteTaskModal] = useState(false);
  const [showCommentSidebar, setShowCommentSidebar] = useState(false);
  const [showExtensionModal, setShowExtensionModal] = useState(false);
  const [taskId, setTaskId] = useState("");

  // const currentUser = useSelector((state) => state.user.currentUser);

  const closeModal = () => setShowDeleteTaskModal(false);

  const closeChatSidebar = () => {
    document.body.style.overflow = "auto";
    setShowCommentSidebar(false);
  };

  const closeExtensionModal = () => {
    setShowExtensionModal(false);
    document.body.style.overflow = "auto";
  };

  const [remainingTime, setRemainingTime] = useState("");

  const [progressPercentage, setProgressPercentage] = useState(0);

  // useEffect(() => {
  //   const createdDate = new Date(task.CreatedDate);
  //   const endDate = new Date(task.EndDate);

  //   const calculateRemainingTime = () => {
  //     const now = new Date();
  //     const difference = endDate - now;

  //     if (difference <= 0) {
  //       // If remaining time is negative or zero, set progress to 100%.
  //       setProgressPercentage("100.0");
  //       return "Time Over ";
  //     }

  //     const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  //     const hours = Math.floor(
  //       (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //     );
  //     const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  //     const seconds = Math.floor((difference % (1000 * 60)) / 1000);

  //     const totalMilliseconds = endDate - createdDate;
  //     const elapsedMilliseconds = endDate - now;
  //     const percentage =
  //       totalMilliseconds <= 0
  //         ? 0
  //         : Math.min(
  //             100,
  //             ((totalMilliseconds - elapsedMilliseconds) / totalMilliseconds) *
  //               100
  //           );

  //     setProgressPercentage(percentage.toFixed(1));

  //     return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  //   };

  //   setRemainingTime(calculateRemainingTime());

  //   const timer = setInterval(() => {
  //     setRemainingTime(calculateRemainingTime());
  //   }, 1000);

  //   return () => clearInterval(timer);
  // }, [task.EndDate, task.CreatedDate]);

  // const [remainingTime, setRemainingTime] = useState("");
  // const [progressPercentage, setProgressPercentage] = useState(0);

  useEffect(() => {
    const createdDate = new Date(task.CreatedDate);
    const endDate = new Date(task.EndDate);
    const now = new Date();

    // Calculate differences in milliseconds
    const diff1 = endDate - createdDate; // Difference between end date and created date
    const diff2 = now - createdDate; // Difference between now and created date

    // Calculate percentage
    let percentage = 0;
    if (diff1 !== 0) {
      percentage = (diff2 / diff1) * 100;
    }

    // Ensure the percentage is between 0 and 100
    const boundedPercentage = Math.max(0, Math.min(100, percentage));

    // Set progress percentage state
    setProgressPercentage(boundedPercentage.toFixed(1));

    // Calculate remaining time
    const difference = endDate - now;
    if (difference <= 0) {
      // If remaining time is negative or zero, set progress to 100%.
      setRemainingTime("Time Over");
    } else {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);
      setRemainingTime(`${days}d ${hours}h ${minutes}m ${seconds}s`);
    }

    const timer = setInterval(() => {
      const difference = endDate - new Date();
      if (difference <= 0) {
        // If remaining time is negative or zero, set progress to 100%.
        setRemainingTime("Time Over");
        setProgressPercentage("100.0");
      } else {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);
        setRemainingTime(`${days}d ${hours}h ${minutes}m ${seconds}s`);

        const diff2 = new Date() - createdDate;
        let percentage = 0;
        if (diff1 !== 0) {
          percentage = (diff2 / diff1) * 100;
        }
        const boundedPercentage = Math.max(0, Math.min(100, percentage));
        setProgressPercentage(boundedPercentage.toFixed(1));
        // console.log(boundedPercentage);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [task.EndDate, task.CreatedDate]);

  const [selectedTask, setSlectedTask] = useState({});

  const deleteTask = (id) => {
    // console.log("remove id :- ", id)
    if (id) {
      setShowDeleteTaskModal(true);
      setTaskId(id);
    } else {
      toast.error("something went wrong...");
    }
  };

  function openCommentSidebar(task) {
    // console.log("openCommentSidebar :-", task)
    if (task) {
      // setTaskId(id)
      setSlectedTask(task);
      setShowCommentSidebar(true);
      document.body.style.overflow = "hidden";
    } else {
      alert("something went wrong...");
    }
  }

  function extensionTaskModal(task_id) {
    if (task_id) {
      setShowExtensionModal(true);
      document.body.style.overflow = "hidden";
      setTaskId(task_id);
    } else {
      alert("something went wrong...");
    }
  }

  // ========== dragging feature ===========
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "task",
    item: { id: task.id, taskStatus: task.task_status },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  // const handleRating = async (val) => {
  //   // toast.success(val);
  //   const body = {
  //     token: currentUser.token,
  //     service_name: "rating_save",
  //     data: {
  //       task_id: task?.id,
  //       feedback: val,
  //       feedback_comment: "Dummy_Good",
  //     },
  //   };

  //   try {
  //     const resp = await axios.post("/timesheet-web-api", body);
  //     console.log("task resp :-", resp);

  //     // console.log("values :-", values)

  //     if (resp.data?.status === 1) {
  //       // closeAssignTaskModal(false);
  //       toast.success("Rated successfully...");
  //       handleLoad(true);
  //     } else {
  //       toast.error(resp.data.msg);
  //       console.log("err :-", resp.data.msg);
  //     }
  //   } catch (err) {
  //     console.log("save task error :-", err);
  //     toast.error(err.message);
  //   }
  // };

  // console.log("Progress Percentage: ", progressPercentage, pending);

  // useEffect(() => {
  //   const fetch = async () => {
  //     if (
  //       (progressPercentage >= 100 && pending) ||
  //       (progressPercentage >= 100 && Inprogress)
  //     ) {
  //       console.log("TASK ID: ", task.id);
  //       const body = {
  //         token: currentUser.token,
  //         service_name: "check_hold_and_expiry_task_frontend",
  //         task_id: task.id,
  //       };

  //       try {
  //         const resp = await axios.post("/timesheet-web-api", body);
  //         console.log("task resp :-", resp);

  //         // console.log("values :-", values)

  //         if (resp.data?.status === 1) {
  //           // closeAssignTaskModal(false);
  //           toast.success(resp.data.msg);
  //         } else {
  //           toast.error(resp.data.msg);
  //           console.log("err :-", resp.data.msg);
  //         }
  //       } catch (err) {
  //         console.log("save task error :-", err);
  //         toast.error(err.message);
  //       }
  //     } else {
  //       // alert("NO");
  //     }
  //   };
  //   fetch();
  // }, [progressPercentage, pending, currentUser.token, Inprogress, task.id]);
  return (
    <>
      {isDragging ? (
        <Tooltip
          followCursor
          title={
            <>
              {`Task Name: ${task.title}`} <br />{" "}
              {`Created At: ${task.CreatedDate}`} <br />{" "}
              {`${
                task.StartDate !== ""
                  ? // eslint-disable-next-line
                    "Start Date:" + " " + task.StartDate
                  : ""
              }`}{" "}
              <br />{" "}
              {`${
                // eslint-disable-next-line
                task.completedate !== ""
                  ? // eslint-disable-next-line
                    "Complete Date:" + " " + task.completedate
                  : ""
              }`}
            </>
          }
          arrow
        >
          <div
            className="blank_task_box"
            style={{ backgroundColor: dragBg_color, borderColor: border_color }}
          ></div>
        </Tooltip>
      ) : (
        <Tooltip
          followCursor
          title={
            <>
              {`Task Name: ${task.title}`} <br />{" "}
              {`Created At: ${task.CreatedDate}`} <br />{" "}
              {`${
                task.StartDate !== ""
                  ? // eslint-disable-next-line
                    "Start Date:" + " " + task.StartDate
                  : ""
              }`}{" "}
              <br />{" "}
              {`${
                task.completedate !== ""
                  ? // eslint-disable-next-line
                    "Complete Date:" + " " + task.completedate
                  : ""
              }`}
            </>
          }
          arrow
        >
          <div
            className={`task_box ${isDragging ? "opacity-0" : "opacity-100"}`}
            ref={drag}
          >
            <div className="task_details">
              <span
                className="user_id"
                style={{ backgroundColor: border_color }}
              >
                #{task.id}
              </span>
              <h5 className="task_heading">{task.title}</h5>
              <p className="task_desc">{task.detail}</p>
              {Complete && parseInt(task.feedback) > 0 ? (
                <Rating
                  size="2rem"
                  name="simple-controlled"
                  value={parseInt(task.feedback)}
                  onChange={(event, newValue) => {
                    // setValue(newValue);
                  }}
                />
              ) : null}
              {!SelfTask && (
                <h6 className="task_time">
                  {HTMLReactParser(Icon.calendar_icon)}
                  <p>End Date: {task.enddate}</p>
                </h6>
              )}

              {Iscomplete && (
                <h6 className="task_time verify-date">
                  {task.verifydate && (
                    <>
                      {HTMLReactParser(Icon.calendar_icon)}
                      <p>Verify Date: {task.verifydate}</p>
                    </>
                  )}
                </h6>
              )}

              {/* {pending ? (
                <>
                  <h6 className="task_time">
                    {HTMLReactParser(Icon.calendar_icon)}
                    Created Date: {task.CreatedDate}
                  </h6>
                  <h6 className="task_time">
                    {HTMLReactParser(Icon.calendar_icon)}
                    End Date: {task.enddate}
                  </h6>
                </>
              ) : Inprogress ? (
                <>
                  <h6 className="task_time">
                    {HTMLReactParser(Icon.calendar_icon)}
                    Start Date: {task.StartDate}
                  </h6>
                  <h6 className="task_time">
                    {HTMLReactParser(Icon.calendar_icon)}
                    End Date: {task.enddate}
                  </h6>
                </>
              ) : UnderReview ? (
                <>
                  <h6 className="task_time">
                    {HTMLReactParser(Icon.calendar_icon)}
                    Start Date: {task.StartDate}
                  </h6>
                  <h6 className="task_time">
                    {HTMLReactParser(Icon.calendar_icon)}
                    Complete Date: {task.completedate}
                  </h6>
                  <h6 className="task_time">
                    {HTMLReactParser(Icon.calendar_icon)}
                    End Date: {task.enddate}
                  </h6>
                </>
              ) : Complete ? (
                <>
                  <h6 className="task_time">
                    {HTMLReactParser(Icon.calendar_icon)}
                    Created Date: {task.CreatedDate}
                  </h6>
                  <h6 className="task_time">
                    {HTMLReactParser(Icon.calendar_icon)}
                    Start Date: {task.StartDate}
                  </h6>
                  <h6 className="task_time">
                    {HTMLReactParser(Icon.calendar_icon)}
                    Complete Date: {task.completedate}
                  </h6>
                  <h6 className="task_time">
                    {HTMLReactParser(Icon.calendar_icon)}
                    End Date: {task.enddate}
                  </h6>
                </>
              ) : expired ? (
                <>
                  {task.CreatedDate !== "" && (
                    <h6 className="task_time">
                      {HTMLReactParser(Icon.calendar_icon)}
                      Created Date: {task.CreatedDate}
                    </h6>
                  )}
                  {task.StartDate !== "" && (
                    <h6 className="task_time">
                      {HTMLReactParser(Icon.calendar_icon)}
                      Start Date: {task.StartDate}
                    </h6>
                  )}
                  {task.completedate !== "" && (
                    <h6 className="task_time">
                      {HTMLReactParser(Icon.calendar_icon)}
                      Complete Date: {task.completedate}
                    </h6>
                  )}
                  {task.enddate !== "" && (
                    <h6 className="task_time">
                      {HTMLReactParser(Icon.calendar_icon)}
                      End Date: {task.enddate}
                    </h6>
                  )}
                </>
              ) : hold ? (
                <>
                  {task.CreatedDate !== "" && (
                    <h6 className="task_time">
                      {HTMLReactParser(Icon.calendar_icon)}
                      Created Date: {task.CreatedDate}
                    </h6>
                  )}
                  {task.StartDate !== "" && (
                    <h6 className="task_time">
                      {HTMLReactParser(Icon.calendar_icon)}
                      Start Date: {task.StartDate}
                    </h6>
                  )}
                  {task.completedate !== "" && (
                    <h6 className="task_time">
                      {HTMLReactParser(Icon.calendar_icon)}
                      Complete Date: {task.completedate}
                    </h6>
                  )}
                  {task.enddate !== "" && (
                    <h6 className="task_time">
                      {HTMLReactParser(Icon.calendar_icon)}
                      End Date: {task.enddate}
                    </h6>
                  )}
                </>
              ) : null} */}
              {/* <p>Start Date {task.CreatedDate}</p> */}
              {/* <p>{remainingTime}</p> */}
              {!SelfTask && (
                <div className="task_remaining_time">
                  <p>
                    Time Left
                    <span>
                      {remainingTime}
                      <b>&nbsp;({progressPercentage}%)</b>
                    </span>
                  </p>
                  <div
                    className="progress"
                    role="progressbar"
                    aria-label="Basic example"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <div
                      className="progress-bar"
                      style={
                        progressPercentage >= 70
                          ? {
                              backgroundColor: "#FF0000",
                              width: progressPercentage + "%",
                            }
                          : progressPercentage >= 50
                          ? {
                              backgroundColor: "#FF9F00",
                              width: progressPercentage + "%",
                            }
                          : progressPercentage < 50
                          ? {
                              backgroundColor: "#0BFC15",
                              width: progressPercentage + "%",
                            }
                          : {
                              backgroundColor: "transparent",
                              // eslint-disable-next-line
                              width: (progressPercentage = "%"),
                            }
                      }
                    ></div>
                  </div>
                </div>
              )}
            </div>
            <span className="line" />

            {/* assigner details */}
            <div className="user_details">
              <div
                className="d-flex align-items-center"
                style={{ gap: "6px", width: "100%" }}
              >
                <img
                  onError={(e) => (e.target.src = DEFAULT_IMG)}
                  src={task.AssignerUser.profile_image}
                  alt=""
                />
                <div className="user_name_position">
                  <h4 className="user_name">
                    {assign_Task
                      ? task.AssigneeUser.firstname +
                        " " +
                        task.AssigneeUser.lastname
                      : task.AssignerUser.firstname +
                        " " +
                        task.AssignerUser.lastname}
                  </h4>
                </div>
              </div>
              <div className="user_actions">
                {
                  <>
                    {task.task_status === "Pending" ||
                    task.task_status === "InProgress" ? (
                      <>
                        {!SelfTask && (
                          <button
                            className="cmnt_btn"
                            onClick={() => openCommentSidebar(task)}
                          >
                            {HTMLReactParser(Icon.comment_icon)}
                          </button>
                        )}
                        {isCurrent === task?.AssignerUser?.id && (
                          <button
                            className="delete_btn"
                            onClick={() => deleteTask(task.id)}
                          >
                            {HTMLReactParser(Icon.task_delete_icon)}
                          </button>
                        )}
                        {assign_Task ||
                        isCurrent === task?.AssignerUser?.id ? null : (
                          <button
                            className="delete_btn"
                            onClick={() => extensionTaskModal(task.id)}
                          >
                            {HTMLReactParser(Icon.clock_icon)}
                          </button>
                        )}
                      </>
                    ) : (
                      <>
                        {!SelfTask && (
                          <button
                            className="cmnt_btn"
                            onClick={() => openCommentSidebar(task)}
                          >
                            {HTMLReactParser(Icon.comment_icon)}
                          </button>
                        )}
                      </>
                    )}
                  </>
                }
              </div>
            </div>
          </div>
        </Tooltip>
      )}
      {showCommentSidebar && (
        <ChatBody
          selectedTask={selectedTask}
          closeChatSidebar={closeChatSidebar}
        />
      )}
      {showDeleteTaskModal && (
        <DeleteTaskModal
          taskId={taskId}
          closeModal={closeModal}
          tasks={tasks}
          setTasks={setTasks}
          handleLoad={handleLoad}
        />
      )}
      {showExtensionModal && (
        <ExtensionTaskModal
          taskId={taskId}
          closeExtensionModal={closeExtensionModal}
          endDate={task.enddate}
        />
      )}
    </>
  );
};

export default TaskComponent;
