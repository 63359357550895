import React, { useState } from "react";
import Icon from "../../assets/icons/icon";
import HTMLReactParser from "html-react-parser";
import RestoreTaskModal from "../taskManager/RestoreTaskModal";
import AnimatedLoader from "../common/Loader/AnimatedLoader";

const DeletedTaskTable = ({ deletedTask, setDeletedTask, isLoading }) => {
  // console.log("deletedTask data :-", deletedTask)

  const [taskId, setTaskId] = useState("");
  const [showRestoreTaskModal, setShowRestoreTaskModal] = useState(false);

  function openRestoreModal(task_id) {
    if (task_id) {
      setShowRestoreTaskModal(true);
      setTaskId(task_id);
    } else {
      alert("something went wrong...");
    }
  }

  const closeRestoreModal = () => setShowRestoreTaskModal(false);

  // custom pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 10;
  const lastIndex = currentPage * recordPerPage;
  const firstIndex = lastIndex - recordPerPage;
  const records = deletedTask.slice(firstIndex, lastIndex);
  const npage = Math.ceil(deletedTask.length / recordPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const changeCPage = (pageNo) => {
    setCurrentPage(pageNo);
  };

  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="table_card">
      <table>
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>ID</th>
            <th>Assignee</th>
            <th>Title</th>
            <th>Created at</th>
            <th>Deleted at</th>
            <th>Action</th>
          </tr>
        </thead>
        {isLoading ? (
          // <h1 className='text-center py-4 text-sm'>Loading...</h1>
          <caption className="text-capitalize text-center text-lg pt-4">
            <AnimatedLoader />
          </caption>
        ) : (
          <>
            {deletedTask.length > 0 ? (
              <tbody>
                {records.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{item.id}</td>
                      <td>
                        {item.assignee_name} - {item.assignee_user_id}
                      </td>
                      <td width={300}>{item.title}</td>
                      <td>{item.created}</td>
                      <td>{item.deleted_at}</td>
                      <td>
                        <button
                          className="restore_btn"
                          onClick={() => openRestoreModal(item.id)}
                        >
                          {HTMLReactParser(Icon.restore_icon)}
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <caption className="text-danger text-capitalize text-center pt-4">
                No Data Found
              </caption>
            )}
          </>
        )}
      </table>

      {/* ====== pagination ===== */}
      {numbers.length > 0 && (
        <div className="custom_pagination_container">
          <ul className="custom_pagination">
            <li className="pagination_item prev">
              <button className="pagination_btn" onClick={prevPage}>
                {HTMLReactParser(Icon.prev_icon)}
              </button>
            </li>

            {numbers.map((n, i) => {
              return (
                <li
                  className={`pagination_item ${
                    currentPage === n ? "active" : ""
                  }`}
                  key={i}
                  onClick={() => changeCPage(n)}
                >
                  <span className="pagination_link">{n}</span>
                </li>
              );
            })}

            <li className="pagination_item next">
              <button className="pagination_btn" onClick={nextPage}>
                {HTMLReactParser(Icon.next_icon)}
              </button>
            </li>
          </ul>
        </div>
      )}

      {showRestoreTaskModal && (
        <RestoreTaskModal
          taskId={taskId}
          closeRestoreModal={closeRestoreModal}
          setDeletedTask={setDeletedTask}
        />
      )}
    </div>
  );
};

export default DeletedTaskTable;
