import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    // currentUser : null,
    currentUser : '',
    isLoading : false,
    error : false,
}

export const userSlice = createSlice({
    name : 'user',
    initialState,
    reducers: {
        loginStart : (state) => {
            state.isLoading = true
        },
        loginSuccess : (state, action) => {
            state.isLoading = false
            state.currentUser = action.payload
        },
        loginFailure : (state) => {
            state.isLoading = false
            state.error = true
        },
        logout: (state) => {
            // return initialState
            // ===== or =====
            // state.currentUser = null;
            state.currentUser = '';
            state.isLoading = false;
            state.error = false;
        }
    }
})

export const { loginStart, loginSuccess, loginFailure, logout, isLoading = false } = userSlice.actions

export default userSlice.reducer;