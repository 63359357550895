import React, { useEffect, useState } from "react";
import FilterHeader from "../../../components/taskManager/FilterHeader";
import MyTaskComponent from "../../../components/taskManager/MyTaskComponent";
import { useSelector } from "react-redux";
import axios from "../../../config/axiosinstance";
import { toast } from "react-hot-toast";
import Loader from "../../../components/common/Loader/Loader";
import MetaTitle from "../../../components/MetaTitle/MetaTitle";

const AssignTask = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  // console.log("currentUser in myTask :-", currentUser)

  // const isLoading = useSelector(state => state.user.isLoading)
  const [isLoading, setIsLoading] = useState(false);

  const [assignTasks, setAssignTasks] = useState([]);

  // task_list api
  const handleFetchAssignedTask = async () => {
    setIsLoading(true);
    if (currentUser) {
      const body = {
        token: currentUser.token,
        user_id: currentUser.User.id,
        task_status: "All", //Pending
        service_name: "assigned_task_list",
      };

      // console.log("myTask body :-", body)

      // setIsLoading(true)
      // dispatch(myTaskStart())
      try {
        const resp = await axios.post("/timesheet-web-api", body);
        // console.log("task resp :-", resp)
        if (resp?.data?.status === 1) {
          setIsLoading(false);
          setAssignTasks(resp.data?.data);
        }

        // dispatch(myTaskSuccess(resp.data?.data))
      } catch (err) {
        setIsLoading(false);
        toast.error(`taskList error :- ${err}`);
        console.log("taskList error :-", err);
        // setIsLoading(false)
        // dispatch(myTaskFailure())
      }
    } else {
      console.log("no users found...");
    }
  };

  // console.log("tasks :-", tasks)

  useEffect(() => {
    handleFetchAssignedTask();
    //   setTasks(JSON.parse(localStorage.getItem("myTasks")))
    // eslint-disable-next-line
  }, [currentUser]);

  // useEffect(() => {
  //   setAssignTasks(JSON.parse(localStorage.getItem("assignTasks")))
  // }, [])

  // console.log("assign tasks  >> :-", assignTasks)

  const [addTaskSubmit, setAddTaskSubmit] = useState(false);
  // console.log("<<< addTaskSubmit >>>", addTaskSubmit)

  const handleLoad = (val) => {
    if (val) {
      handleFetchAssignedTask();
    }
  };

  const [valSearch, setValSearch] = useState("");

  const getValue = (val) => {
    setValSearch(val?.id);
    // handleFetchMyTask(val?.id);
    console.log("PROPS: ", val?.id);
  };

  const resetTask = () => {
    handleFetchAssignedTask();
  };
  return (
    <>
      <MetaTitle title={"Assigned Task Board"} />
      {currentUser ? (
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <FilterHeader
                addTaskBtn="false"
                assignTaskBtn="true"
                tasks={assignTasks}
                setTasks={setAssignTasks}
                handleLoad={handleLoad}
                getValue={getValue}
                assignTask={true}
                resetTask={resetTask}
              />
              {/* <MyTaskComponent tasks={assignTasks} setTasks={setAssignTasks} /> */}
              <MyTaskComponent
                assign_Task={true}
                addTaskSubmit={addTaskSubmit}
                setAddTaskSubmit={setAddTaskSubmit}
                handleLoad={handleLoad}
                valSearch={valSearch}
              />
            </>
          )}
        </>
      ) : (
        <>
          <h1 className="text-center py-4 text-capitalize d-flex align-items-center justify-content-center">
            user not authenticated please login first
            {/* Loading... */}
          </h1>
        </>
      )}
    </>
  );
};

export default AssignTask;
