import React from "react";
import './AnimatedLoader.css'

const AnimatedLoader = () => {
  return (
    <div
      className="loading-google-spinner"
    >
      <svg className="loading-google-spinner__circle-svg" viewBox="25 25 50 50">
        <circle
          class="loading-google-spinner__circle-stroke"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke-width="2"
          stroke-miterlimit="10"
        />
      </svg>
    </div>
  );
};

export default AnimatedLoader;
