import React from 'react'
import Icons from '../../assets/icons/icon'
import HTMLReactParser from 'html-react-parser'
import { useSelector } from 'react-redux'
import axios from '../../config/axiosinstance'

const RestoreTaskModal = ({closeRestoreModal , taskId, setDeletedTask}) => {
  // console.log("deleteTaskId :-",taskId)

  const currentUser = useSelector(state => state.user.currentUser)
  
  const fetchDeletedTask = async () => {
    const body = {
      token: currentUser.token,
      assigner_user_id: currentUser?.User.id,
      service_name: 'deleted_task_list'
    }
    try {
      const resp = await axios.post("/timesheet-web-api", body)
      // console.log("deleted task resp :-", resp)
      setDeletedTask(resp.data?.data)
    } catch (err) {
      console.log("taskList error :-", err)
    }
  }


  const restoreTask = async (id) => {
    // console.log(id)
    const body = {
      service_name: 'task_undo_delete',
      token: currentUser?.token,
      task_id: id,
      assigner_user_id: currentUser?.User.id,
      request_type: 'Undo'
    }
    try {
      const resp = await axios.post("/timesheet-web-api", body)
      // console.log("task undo resp :-", resp)
      if(resp.data?.status === 1){
        // setDeletedTask(resp.data?.data)
        fetchDeletedTask();
        closeRestoreModal()
      }
    } catch (err) {
      console.log("taskList error :-", err)
    }
  }

  return (
    <div className='custom_modal restore_modal'>
        <div className="custom_modal_backdrop"></div>
        <div className="custom_modal_container">
            <div className="custom_modal_header">
              <h4>task ID - <span className='text-danger ms-2'>{taskId}</span></h4>
            </div>
            <div className="custom_modal_body">
                <i>{HTMLReactParser(Icons.restore_icon_modal)}</i>
                <h4>restore</h4>
                <p>Are you sure do you want to Undo this task?</p>
            </div>
            <div className="custom_modal_footer">
                <button className='cancel_btn danger_btn' onClick={closeRestoreModal}>no</button>
                <button className='submit_btn' type='submit' onClick={() => restoreTask(taskId)}>yes</button>
            </div>
        </div>
    </div>
  )
}

export default RestoreTaskModal
