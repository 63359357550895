import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import HTMLReactParser from "html-react-parser";
import Icon from "../../assets/icons/icon";
import { useSelector } from "react-redux";
import { DEFAULT_IMG } from "../../DefaultImg";

const MasterUserList = ({
  userLists,
  initialUserId,
  valSearch,
  handleuserId,
  assigned_task_board,
}) => {
  // console.log("userLists in MasteruserList component :-", userLists)
  const currentUser = useSelector((state) => state.user.currentUser);

  const [searchQuery, setSearchQuery] = useState("");
  const [users, setUsers] = useState([]);

  const search = (data) => {
    const searchData = data.filter((item) =>
      item.firstname.toLowerCase().includes(searchQuery)
    );
    if (searchData.length === 0) {
      // console.log(searchData)
      setUsers([]);
    } else {
      setUsers(searchData);
    }
  };

  useEffect(() => {
    // console.log("<<< searchQuery >>>", searchQuery)
    // setUsers([])
    search(userLists);
    // eslint-disable-next-line
  }, [searchQuery]);

  // useEffect(() => {
  //     search(userLists);
  // }, [users])

  // console.log("<<< user >>>", users[0]?.id)

  const navigate = useNavigate();

  // console.log("<<< initialUserId >>>", initialUserId)
  useEffect(() => {
    if (users.length > 0) {
      let userId = "";
      if (initialUserId) {
        userId = `user/${currentUser?.User?.id}`;
        handleuserId && handleuserId(currentUser?.User?.id);
        if (valSearch !== "" && valSearch !== null && valSearch !== undefined) {
          userId += `/${valSearch}`;
        }
        // if (
        //   assigned_task_board === true &&
        //   assigned_task_board !== null &&
        //   assigned_task_board !== undefined
        // ) {
        //   userId += `/${assigned_task_board}`;
        // }
      } else {
        userId = `user/${users[0]?.id}`;
        handleuserId && handleuserId(users[0]?.id);
        if (valSearch !== "" && valSearch !== null && valSearch !== undefined) {
          userId += `/${valSearch}`;
        }
        // if (
        //   assigned_task_board === true &&
        //   assigned_task_board !== null &&
        //   assigned_task_board !== undefined
        // ) {
        //   userId += `/${assigned_task_board}`;
        // }
      }

      navigate(`${userId}`);
    }
    // eslint-disable-next-line
  }, [users, valSearch]);

  console.log("VAL SEARCH: ", valSearch);

  return (
    <>
      <div className="task_header search_input">
        <input
          type="search"
          placeholder="searching..."
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
        />
        {/* <input type="search" placeholder="searching..." onChange={filterUsers}/> */}
        {HTMLReactParser(Icon.search_icon)}
      </div>
      {users.length !== 0 ? (
        <ul className="user_list">
          {users.map((item) => {
            return (
              <li key={item.id}>
                <NavLink
                  onClick={() => handleuserId && handleuserId(item.id)}
                  to={
                    assigned_task_board !== null &&
                    assigned_task_board !== undefined &&
                    assigned_task_board
                      ? `user/${item.id}/${assigned_task_board}`
                      : `user/${item.id}`
                  }
                >
                  <img
                    onError={(e) => (e.target.src = DEFAULT_IMG)}
                    src={item.profile_image_thumbnail}
                    alt=""
                  />
                  <div className="user_details">
                    {/* <h4>{item.userName}</h4>
                                        <h6>{item.designation}</h6> */}
                    <h4>
                      {item.firstname} {item.lastname}
                    </h4>
                  </div>
                </NavLink>
                <span className="line" />
              </li>
            );
          })}
        </ul>
      ) : (
        <p className="text-danger text-center mt-3">No Records Found</p>
      )}
    </>
  );
};

export default MasterUserList;
