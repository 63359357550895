import React from "react";
import HTMLReactParser from "html-react-parser";
import Icon from "../../assets/icons/icon";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import MasterUserList from "./MasterUserList";

const PendingTaskSidebar = ({ assignedUsers, masterUsers, initialUserId }) => {
  const currentUser = useSelector((state) => state.user.currentUser);
  // console.log("currentUser :-", currentUser)

  // console.log("<<< assignedUsers >>>>", assignedUsers)

  // console.log("<<< masterUsers >>>>", masterUsers)

  // const [initialId, seInitialId] = useState(true)

  console.log("Master Users: ", masterUsers);
  return (
    <>
      {/* === my task */}
      <div className="task_card">
        <div className="pending_task_body">
          {/* <NavLink to="user/user_id?"> */}
          <NavLink to={"user/" + currentUser?.User.id}>
            <div className="p_task_detaiks">
              {HTMLReactParser(Icon.my_task_icon)}
              <h4>My Task Board</h4>
            </div>
            <i>{HTMLReactParser(Icon.accordion_icon)}</i>
          </NavLink>
        </div>
      </div>

      {/* ===== assign task */}
      <div className="task_card mt-3">
        <div className="accordion" id="">
          <div className="accordion-item">
            <div className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#assignTaskCollapse"
                aria-expanded="true"
                aria-controls="assignTaskCollapse"
              >
                <div className="accordion_btn_title">
                  {HTMLReactParser(Icon.assign_task_icon)}
                  <h4>Assigned Task Board</h4>
                </div>
                <span className="accordion_icon">
                  {HTMLReactParser(Icon.accordion_icon)}
                </span>
              </button>
            </div>
            <div
              id="assignTaskCollapse"
              className="accordion-collapse collapse show"
              data-bs-parent=""
            >
              {assignedUsers.length > 0 && (
                <div
                  className="accordion-body"
                  style={
                    assignedUsers.length < 5
                      ? { height: "auto" }
                      : { height: "" }
                  }
                >
                  <MasterUserList
                    userLists={assignedUsers}
                    initialUserId={initialUserId}
                    assigned_task_board={true}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* ===== assign task */}
      <div className="task_card mt-3">
        <div className="accordion">
          <div className="accordion-item">
            <div className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#masterTaskCollapse"
                aria-expanded="true"
                aria-controls="masterTaskCollapse"
              >
                <div className="accordion_btn_title">
                  {HTMLReactParser(Icon.master_task_icon)}
                  <h4>Master Task Board</h4>
                </div>
                <span className="accordion_icon">
                  {HTMLReactParser(Icon.accordion_icon)}
                </span>
              </button>
            </div>
            <div
              id="masterTaskCollapse"
              className="accordion-collapse collapse show"
            >
              {masterUsers.length > 0 && (
                <div
                  className="accordion-body"
                  style={
                    masterUsers.length < 5 ? { height: "auto" } : { height: "" }
                  }
                >
                  <MasterUserList
                    userLists={masterUsers}
                    initialUserId={initialUserId}
                    assigned_task_board={false}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PendingTaskSidebar;
