import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ConfirmPasswordModal from "../../confirmPassword/ConfirmPasswordModal";
import { useDrop } from "react-dnd";
import axios from "../../../config/axiosinstance";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-hot-toast";
import TaskComponent from "../TaskComponent";
import { useLocation } from "react-router-dom";
import AnimatedLoader from "../../common/Loader/AnimatedLoader";
const UnderReview = ({
  masterUserTask,
  paramsUserId,
  passwordMatched,
  setPasswordMatched,
  handleLoad,
  assign_Task,
  myTaskBoard,
  valSearch,
}) => {
  const currentUser = useSelector((state) => state.user.currentUser);
  // console.log("currentUser in myTask :-", currentUser)

  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const [locationPath, setLocationPath] = useState("");

  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const closePasswordModal = () => setShowPasswordModal(false);
  const [taskId, setTaskId] = useState("");
  // const [passwordMatched, setPasswordMatched] = useState(Boolean)

  // useEffect(() => {
  //     setPasswordMatched(false)
  // }, [passwordMatched])
  // console.log("<<<< underReview - passwordMatched :-", passwordMatched)

  let title = "Under Review";
  let status = "UnderReview";
  let bg_color = "#EFE5FF";
  let border_color = "#B485FF";
  let dragBg_color = "";

  // ============ drag and drop
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "task",
    drop: (item) => addItemToSection(item.id, item.taskStatus),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  // console.log("isOver :-",isOver)

  // console.log("tasks <<< :-", tasks)
  const addItemToSection = (id, taskStatus) => {
    console.log("dropped id is:-", id, taskStatus, status);
    if (masterUserTask && taskStatus === "Pending") {
      toast.error("Operation Doesn't Perform...");
      return;
    }
    if (
      assign_Task &&
      (taskStatus === "Pending" ||
        taskStatus === "InProgress" ||
        taskStatus === "Complete")
    ) {
      toast.error("Operation Doesn't Perform...");
      return;
    }
    if (myTaskBoard && taskStatus === "Pending") {
      toast.error("Operation Doesn't Perform...");
      return;
    }
    if (
      taskStatus === status ||
      taskStatus === "Complete" ||
      taskStatus === "Hold" ||
      taskStatus === "Expired"
    ) {
      toast.error("Operation Doesn't Perform...");
    } else {
      if (id) {
        setTaskId(id);
        setShowPasswordModal(true);
      }
    }
    // changeTaskStatus(id)
  };

  useEffect(() => {
    if (passwordMatched) {
      console.log("password matched");
      // console.log("<<< taskId >>>", taskId)
      changeTaskStatus(taskId);
    }
    // eslint-disable-next-line
  }, [passwordMatched]);

  const [isChanged, setIsChanged] = useState(false);

  const changeTaskStatus = async (id) => {
    // console.log("<<<< change task status >>>", id)
    if (id) {
      // update task status
      const body = {
        service_name: "task_status_change",
        token: currentUser?.token,
        task_id: id,
        status: status,
      };

      try {
        const resp = await axios.post("/timesheet-web-api", body);
        // console.log("status change resp :-", resp)
        if (resp?.data?.status === 1) {
          setIsChanged(true);
          toast.success(
            `Task ${resp?.data?.task_id} Status Changed Successfully...`
          );
          // fetchTaskList();
          handleFetchMyTask();
        } else if (resp?.data?.status === 0) {
          setIsChanged(true);
          toast.error(`${resp?.data?.msg}`);
        }
      } catch (err) {
        console.log("taskList error :-", err);
      }
    }
    // else{
    //     toast.error(`Error :- Id is ${id}`)
    // }
  };

  const [hasMore, setHasMore] = useState(true);
  const [totalTask, setTotalTask] = useState();

  const page_limit = 10;
  // let pageNo = Number;
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    // console.log("<<< locationPath >>>", location)
    setLocationPath(location.pathname);
    // console.log("<<< locationPath >>>", locationPath)
    // eslint-disable-next-line
  }, [locationPath]);

  // task_list api
  const handleFetchMyTask = async (x) => {
    setIsLoading(true);
    // if(assignTaskSubmit){
    //     pageNo = 1
    // }else{
    //     pageNo = Math.ceil(tasks.length / page_limit) + 1
    // }
    // pageNo = Math.ceil(tasks.length / page_limit) + 1;
    setPageNo(Math.ceil(tasks.length / page_limit) + 1);
    // console.log("<<< pageNo >>>", pageNo)

    let body = {};

    if (locationPath && locationPath === "/taskManager/my-task") {
      body = {
        service_name: "task_list",
        token: currentUser.token,
        assignee_user_id: currentUser.User.id,
        status: "UnderReview", //Pending , All
        page: pageNo,
        limit: page_limit,
        search_task_id: x,
      };
    } else if (locationPath && locationPath === "/taskManager/assign-task") {
      body = {
        service_name: "assigned_task_list",
        token: currentUser.token,
        user_id: currentUser.User.id,
        task_status: "UnderReview", //Pending , All
        page: pageNo,
        limit: page_limit,
        search_task_id: x,
      };
    } else if (masterUserTask) {
      body = {
        service_name: "master_task_list",
        token: currentUser.token,
        // task_status: "InProgress", //Pending , All
        task_status: "UnderReview", //Pending , All
        user_id: paramsUserId,
        page: pageNo,
        limit: page_limit,
        search_task_id: x,
      };
    }

    if (currentUser) {
      // console.log("<<< underReview body >>>", body)
      if (Object.keys(body).length !== 0) {
        try {
          const resp = await axios.post("/timesheet-web-api", body);
          // console.log("pending task resp :-", resp)
          // console.log("pending task resp data :-", resp.data?.data)
          if (resp?.data.status === 1) {
            // setTasks(resp.data?.data)
            setTotalTask(resp?.data?.total);
            setIsLoading(false);
            setPageNo((prev) => prev + 1);
            if (x) {
              const mergeData = resp.data?.data;
              setTasks(mergeData);
            } else {
              const mergeData = [...tasks, ...resp.data?.data];
              setTasks(mergeData);
            }
          }
        } catch (err) {
          setIsLoading(false);
          console.log("taskList error :-", err);
          toast.error(`taskList error :- ${err}`);
        }
      } else {
        setIsLoading(true);
      }
    } else {
      console.log("no users found...");
    }
  };

  // console.log("<<<< inProgress total task >>>>", totalTask)

  const fetchMoreTask = () => {
    console.log("<<< fetch more task  api >>>");
    if (tasks.length !== totalTask) {
      setTimeout(() => {
        // console.log("<<< fetch more task  api >>>")
        handleFetchMyTask();
        // console.log("<<< tasks >>>", tasks)
      }, 2000);
    } else {
      setHasMore(false);
    }
  };

  // useEffect(() => {
  //     handleFetchMyTask();
  // }, [currentUser])

  useEffect(() => {
    if (locationPath || locationPath !== undefined || isChanged) {
      handleLoad(isChanged);
      handleFetchMyTask();
      setIsChanged(false);
    }
    // eslint-disable-next-line
  }, [locationPath, isChanged]);

  console.log(tasks, "underreview tasks");

  useEffect(() => {
    if (valSearch) {
      handleFetchMyTask(valSearch);
      setPageNo(1);
    }
    // eslint-disable-next-line
  }, [valSearch]);

  return (
    <>
      <div
        ref={drop}
        className={tasks.length > 2 ? "task_card" : "task_card height_auto"}
        style={{ backgroundColor: bg_color, borderColor: border_color }}
      >
        <div className="task_header">
          <h4 className="task_title">{title}</h4>
          <span className="task_count">
            {/* {tasks.length} */}
            {!totalTask ? 0 : totalTask}
          </span>
        </div>
        {isLoading ? (
          <AnimatedLoader />
        ) : (
          <div
            className="task_body"
            id="underReviewScrollContainer"
            style={
              tasks.length > 3
                ? { paddingBottom: "0px" }
                : { paddingBottom: "16px" }
            }
          >
            {tasks?.length > 0 ? (
              <InfiniteScroll
                dataLength={tasks.length}
                next={fetchMoreTask}
                hasMore={hasMore}
                loader={<p className="text-center text-dark">Loading...</p>}
                endMessage={
                  <p className="text-center text-dark">Task Finished...</p>
                }
                // height={100}
                scrollableTarget="underReviewScrollContainer"
              >
                {tasks?.map((task) => {
                  // return <h1 key={task.id}>{task?.Task.id}</h1>
                  return (
                    <TaskComponent
                      key={task?.Task.id}
                      border_color={border_color}
                      dragBg_color={dragBg_color}
                      task={task?.Task}
                      tasks={tasks}
                      setTasks={setTasks}
                      UnderReview={true}
                      handleLoad={handleLoad}
                    />
                  );
                })}
              </InfiniteScroll>
            ) : (
              <p className="text-danger text-capitalize">
                {title} - tasks items are not Available
              </p>
            )}

            {isOver && (
              <div
                className="blank_task_box"
                style={{
                  backgroundColor: dragBg_color,
                  borderColor: border_color,
                }}
              >
                <h6 style={{ color: border_color }}>drop here</h6>
              </div>
            )}
          </div>
        )}
      </div>

      {showPasswordModal && (
        <ConfirmPasswordModal
          taskId={taskId}
          closePasswordModal={closePasswordModal}
          passwordMatched={passwordMatched}
          setPasswordMatched={setPasswordMatched}
        />
      )}
    </>
  );
};

export default UnderReview;
