import * as Yup from "yup";

export const loginSchema = Yup.object({
  username: Yup.string().required("Please Enter Username..."),
  password: Yup.string().required("Please Enter Password..."),
});

export const addTaskSchema = Yup.object({
  title: Yup.string()
    .min(2)
    .max(50)
    .required("task title is required...")
    .test(
      "no-special-characters",
      "Special characters are not allowed",
      (value) => !/[!@#$%^&*(),.?":{}|<>]/.test(value)
    ),
  details: Yup.string()
    .min(2)
    .max(150)
    .required("task details is required...")
    .test(
      "no-special-characters",
      "Special characters are not allowed",
      (value) => !/[!@#$%^&*(),.?":{}|<>]/.test(value)
    ),
  end_date: Yup.string(),
});

// const SUPPORTED_FORMATS = ["image/jpg, "image/jpeg", "image/png"]
export const chatSchema = Yup.object({
  msg: Yup.string().required("comment is required..."),
  attach_file: Yup.mixed()
    .nullable()
    .test(
      "FILE_SIZE",
      "upload file is too long.",
      (value) => !value || (value && value.size <= 1024 * 1024)
    ),
});

// ==== confirm password =====
export const confirmPasswordSchema = Yup.object({
  password: Yup.string().min(3).required("Please Enter Password..."),
});

// ==== assignTask ==========
export const assignTaskSchema = Yup.object({
  task_title: Yup.string()
    .min(2)
    .max(50)
    .required("task title is required...")
    .test(
      "no-special-characters",
      "Special characters are not allowed",
      (value) => !/[!@#$%^&*(),.?":{}|<>]/.test(value)
    ),
  task_details: Yup.string()
    .min(2)
    .max(1000)
    .required("task details is required...")
    .test(
      "no-special-characters",
      "Special characters are not allowed",
      (value) => !/[!@#$%^&*(),.?":{}|<>]/.test(value)
    ),
  select_member: Yup.string().required("select members is required..."),
  // reward_types: Yup.string().required("select reward types is required..."),
  reward_points: Yup.string(),
  // strike_types: Yup.string().required("select strike type is required..."),
  strike_points: Yup.string(),
  task_end_date: Yup.string(),
});

// ==== extensionTask ====
export const extensionTaskSchema = Yup.object({
  extend_reason: Yup.string()
    .min(2)
    .max(100)
    .required("task details is required..."),
  task_end_date: Yup.date().required("task date is required..."),
});

// ==== approve request extension ==========
export const approveRequestTaskSchema = Yup.object({
  // task_id : Yup.string(),
  // task_title : Yup.string(),
  // request_by : Yup.string(),
  // request_end_date : Yup.string(),
  // request_end_time : Yup.string(),
  // reason : Yup.string(),
  remarks: Yup.string().min(2).max(100).required("remarks is required..."),
  task_date: Yup.string().required("task date is required..."),
});
