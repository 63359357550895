import React, { useState } from 'react'
import { DEFAULT_IMG } from '../../DefaultImg';

const PreviewImage = ({file}) => {
//   console.log("file preview :-", file)
  const [preview, setPreview] = useState(null)
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    setPreview(reader.result)
  }
  return (
    <div className='file_preview'>
        {/* <button onClick={()=>setPreview()}>removeimg</button> */}
        {
            preview ? <img onError={(e) => (e.target.src = DEFAULT_IMG)} src={preview} alt={preview}/> : "loading..."
        }
    </div>
  )
}

export default PreviewImage
