import React, { useEffect, useState } from "react";
import RequestExtensionTable from "../../../components/table/RequestExtensionTable";
import FilterHeader from "../../../components/taskManager/FilterHeader";
import { useSelector } from "react-redux";
import axios from "../../../config/axiosinstance";
import { toast } from "react-hot-toast";
import MetaTitle from "../../../components/MetaTitle/MetaTitle";

const RequestExtension = () => {
  const currentUser = useSelector((state) => state.user.currentUser);

  const [requestApprovalTask, setRequestApprovalTask] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [valSearch, setValSearch] = useState("");

  const fetchDateExtensionTask = async (id) => {
    setIsLoading(true);
    const body = {
      service_name: "task_date_extension_list",
      token: currentUser.token,
      user_id: currentUser?.User.id,
      search_task_id: id,
    };
    try {
      const resp = await axios.post("/timesheet-web-api", body);
      // console.log("request extension task resp :-", resp)
      setIsLoading(false);
      if (resp?.data.status === 1) {
        setRequestApprovalTask(resp.data?.data);
      } else {
        console.log("error >>>", resp?.data?.msg);
        // alert("error :-", resp?.data?.msg)
        toast.error(`Error :- ${resp?.data?.msg}`);
      }
    } catch (err) {
      setIsLoading(false);
      console.log("taskList error :-", err);
      toast.error("taskList error :-", err);
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchDateExtensionTask();
    } else {
      console.log("no users found...");
    }
    // eslint-disable-next-line
  }, [currentUser]);

  // console.log("requestApprovalTask is:-", requestApprovalTask)

  const getValue = (val) => {
    setValSearch(val?.id);
    fetchDateExtensionTask(val?.id);
    // handleFetchMyTask(val?.id);
    console.log("PROPS: ", val.value);
  };

  const resetTask = () => {
    fetchDateExtensionTask();
  };

  console.log(valSearch);
  return (
    <div className="request_extension_page">
      <MetaTitle title={`Request Extension Pending Approval`} />
      {currentUser ? (
        <>
          <FilterHeader
            getValue={getValue}
            extension={true}
            addTaskBtn="false"
            assignTaskBtn="false"
            resetTask={resetTask}
          />
          <RequestExtensionTable
            requestApprovalTask={requestApprovalTask}
            isLoading={isLoading}
          />
        </>
      ) : (
        <h1 className="text-center py-4 text-capitalize d-flex align-items-center justify-content-center">
          user not authenticated please login first
          {/* loading... */}
        </h1>
      )}
    </div>
  );
};

export default RequestExtension;
