import React, { useEffect, useState } from "react";
import PendingTask from "./tasksCardByStatus/PendingTask";
import InProgressTask from "./tasksCardByStatus/InProgressTask";
import UnderReview from "./tasksCardByStatus/UnderReview";
import Complete from "./tasksCardByStatus/Complete";
import Expired from "./tasksCardByStatus/Expired";
import Hold from "./tasksCardByStatus/Hold";

const MyTaskComponent = ({
  tasks,
  setTasks,
  pendingTaskStatus,
  userId,
  addTaskSubmit,
  masterUserTask,
  paramsUserId,
  assign_Task,
  handleLoad,
  SelfTask,
  myTaskBoard,
  valSearch,
}) => {
  // console.log("my task component task list :-", tasks)

  // console.log("<<< masterUserTask >>>", masterUserTask)

  const [pending, setPending] = useState([]);
  const [inProgress, setInProgress] = useState([]);
  const [underReview, setUnderReview] = useState([]);
  const [done, setDone] = useState([]);
  const [expired, setExpired] = useState([]);
  const [hold, setHold] = useState([]);

  useEffect(() => {
    if (tasks) {
      console.log("<<<< tasks >>>>>", tasks);

      let initailTasks = [];

      tasks.forEach((task) => {
        // console.log("task :----", task)
        if (!initailTasks.includes(task.Task)) {
          initailTasks.push(task.Task);
        }
      });

      console.log("initailTasks :-", initailTasks);

      // const filterPending = tasks.filter(task => task.status === "todo")
      // const filterInProgress = tasks.filter(task => task.status === "inprogress")
      // const filterUnderReview = tasks.filter(task => task.status === "underreview")
      // const filterDone = tasks.filter(task => task.status === "done")
      // const filterExpired = tasks.filter(task => task.status === "expired")
      // const filterHold = tasks.filter(task => task.status === "hold")

      const filterPending = initailTasks.filter(
        (task) => task.task_status === "Pending"
      );
      const filterInProgress = initailTasks.filter(
        (task) => task.task_status === "InProgress"
      );
      const filterUnderReview = initailTasks.filter(
        (task) => task.task_status === "UnderReview"
      );
      const filterDone = initailTasks.filter(
        (task) => task.task_status === "Complete"
      );
      const filterExpired = initailTasks.filter(
        (task) => task.task_status === "Expired"
      );
      const filterHold = initailTasks.filter(
        (task) => task.task_status === "Hold"
      );

      setPending(filterPending);
      setInProgress(filterInProgress);
      setUnderReview(filterUnderReview);
      setDone(filterDone);
      setExpired(filterExpired);
      setHold(filterHold);
    }
  }, [tasks]);

  console.log("pendingTaskStatus :-", underReview);

  // var statuses = []

  // if (pendingTaskStatus === true) {
  //     statuses = ["Pending", "InProgress"]
  // }
  // else {
  //     statuses = ["Pending", "InProgress", "underReview", "complete", "expired", "hold"]
  // }

  // console.log("tasks :-", tasks)

  const [passwordMatched, setPasswordMatched] = useState(Boolean);

  useEffect(() => {
    if (passwordMatched) setPasswordMatched(false);
  }, [passwordMatched]);

  // console.log("<<<< myTaskComponent - passwordMatched :-", passwordMatched)

  return (
    <div className="task_container w-100">
      {/* ==== TODO ==== */}
      <PendingTask
        pending={pending}
        setPending={setPending}
        addTaskSubmit={addTaskSubmit}
        masterUserTask={masterUserTask}
        paramsUserId={paramsUserId}
        passwordMatched={passwordMatched}
        setPasswordMatched={setPasswordMatched}
        assign_Task={assign_Task}
        handleLoad={handleLoad}
        SelfTask={SelfTask}
        myTaskBoard={myTaskBoard}
        valSearch={valSearch}
      />
      {/* ==== inprogress ==== */}
      <InProgressTask
        inProgress={inProgress}
        setInProgress={setInProgress}
        addTaskSubmit={addTaskSubmit}
        masterUserTask={masterUserTask}
        paramsUserId={paramsUserId}
        passwordMatched={passwordMatched}
        setPasswordMatched={setPasswordMatched}
        assign_Task={assign_Task}
        handleLoad={handleLoad}
        SelfTask={SelfTask}
        myTaskBoard={myTaskBoard}
        valSearch={valSearch}
      />
      {/* ==== underReview ==== */}
      {!SelfTask && (
        <UnderReview
          underReview={underReview}
          setUnderReview={setUnderReview}
          addTaskSubmit={addTaskSubmit}
          masterUserTask={masterUserTask}
          paramsUserId={paramsUserId}
          passwordMatched={passwordMatched}
          setPasswordMatched={setPasswordMatched}
          handleLoad={handleLoad}
          assign_Task={assign_Task}
          myTaskBoard={myTaskBoard}
          valSearch={valSearch}
        />
      )}
      {/* ==== complete ==== */}
      <Complete
        done={done}
        setDone={setDone}
        addTaskSubmit={addTaskSubmit}
        masterUserTask={masterUserTask}
        paramsUserId={paramsUserId}
        passwordMatched={passwordMatched}
        setPasswordMatched={setPasswordMatched}
        assign_Task={assign_Task}
        handleLoad={handleLoad}
        SelfTask={SelfTask}
        valSearch={valSearch}
        Iscomplete={true}
      />

      {!SelfTask && (
        <>
          {/* ==== expired ==== */}
          <Expired
            expired={expired}
            setExpired={setExpired}
            addTaskSubmit={addTaskSubmit}
            masterUserTask={masterUserTask}
            paramsUserId={paramsUserId}
            passwordMatched={passwordMatched}
            setPasswordMatched={setPasswordMatched}
            valSearch={valSearch}
            assign_Task={assign_Task}
          />

          {/* ==== HOLD ==== */}
          <Hold
            hold={hold}
            setHold={setHold}
            addTaskSubmit={addTaskSubmit}
            masterUserTask={masterUserTask}
            paramsUserId={paramsUserId}
            passwordMatched={passwordMatched}
            setPasswordMatched={setPasswordMatched}
            valSearch={valSearch}
          />
        </>
      )}
    </div>
  );
};

export default MyTaskComponent;
