import React from "react";
import ChatContent from "./ChatContent";
import "./chatBody.scss";
import Icons from "../../assets/icons/icon";
import HTMLReactParser from "html-react-parser";
// import { useSelector } from 'react-redux';

const ChatBody = ({ closeChatSidebar, selectedTask }) => {
  // console.log("chatBody taskId :-", taskId , closeChatSidebar)
  // console.log(Icons)
  // console.log("<<< chat Body task >>>", selectedTask)

  // const currentUser = useSelector(state => state.user.currentUser)

  // console.log("<<< currentUser >>>", currentUser)
  return (
    <div className="chat_body">
      <div onClick={closeChatSidebar} className="close_chat">
        <i>
          {HTMLReactParser(Icons.round_close_icon)}
        </i>
      </div>
      <ChatContent selectedTask={selectedTask} />
    </div>
  );
};

export default ChatBody;
