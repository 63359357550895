import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MyTaskComponent from "./MyTaskComponent";
import axios from "../../config/axiosinstance";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import AnimatedLoader from "../common/Loader/AnimatedLoader";

const UserTask = () => {
  const { searchId } = useParams();
  const currentUser = useSelector((state) => state.user.currentUser);
  // console.log("currentUser in user list :-", currentUser)
  // const [deadlineRequestBtn, setDeadlineRequestBtn] = useState(false);

  let params = useParams();
  // console.log("params >>> :-", params)
  const userId = params.id;
  // console.log("userId :-", userId)

  const [userTasks, setUserTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchMasterTask = async () => {
    setIsLoading(true);
    const body = {
      token: currentUser.token,
      user_id: userId,
      task_status: "All", //Pending
      service_name: "master_task_list",
    };
    try {
      const resp = await axios.post("/timesheet-web-api", body);
      // console.log("master task resp :-", resp)
      if (resp?.data.status === 1) {
        setIsLoading(false);
        setUserTasks(resp.data?.data);
      } else {
        setIsLoading(false);
        toast.error(`Error :- ${resp?.data?.msg}`);
      }
    } catch (err) {
      setIsLoading(false);
      console.log("taskList error :-", err);
      toast.error(`taskList error :- ${err}`);
    }
  };

  useEffect(() => {
    // setUserTasks(JSON.parse(localStorage.getItem("userTasks")))
    fetchMasterTask();
    // eslint-disable-next-line
  }, [userId]);

  // console.log("user tasks :-", userTasks)

  const handleLoad = (val) => {
    if (val) {
      fetchMasterTask();
    }
  };

  console.log("SEARCH PARAMS: ", searchId);

  return (
    // <div className="">
    <>
      {isLoading ? (
        <AnimatedLoader />
      ) : (
        <>
          {userTasks === null ? (
            <>
              <p className="text-danger">
                task are not available for this user id & user id is :-{" "}
                <b>{userId}</b>
              </p>
            </>
          ) : (
            <>
              <MyTaskComponent
                handleLoad={handleLoad}
                tasks={userTasks}
                setTasks={setUserTasks}
                masterUserTask={true}
                paramsUserId={userId}
                valSearch={searchId}
              />
            </>
          )}
        </>
      )}
    </>
    // </div>
  );
};

export default UserTask;
