import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import FilterHeader from "../../../components/taskManager/FilterHeader";
import { useSelector } from "react-redux";
import axios from "../../../config/axiosinstance";
import MasterUserList from "../../../components/taskManager/MasterUserList";
import { toast } from "react-hot-toast";
import Loader from "../../../components/common/Loader/Loader";
import MetaTitle from "../../../components/MetaTitle/MetaTitle";

const MasterTask = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [userLists, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getUserList = async () => {
    setIsLoading(true);
    if (currentUser) {
      try {
        const body = {
          service_name: "master_user_list",
          token: currentUser?.token,
          user_id: currentUser?.User.id,
        };
        const resp = await axios.post("/timesheet-web-api", body);
        // console.log('resp "-', resp)
        // console.log('users "-', resp?.data?.data)
        if (resp?.data?.status === 1) {
          setIsLoading(false);
          setUserList(resp?.data?.data);
        }
      } catch (error) {
        setIsLoading(false);
        console.log("error :-", error);
        toast.error(`Error :- ${error}`);
      }
    } else {
      setIsLoading(false);
      console.log("no users found...");
    }
  };

  useEffect(() => {
    getUserList();
    // eslint-disable-next-line
  }, []);

  // const [initialUserId, setInitialUserId] = useState(false)

  // const handleLoad = (val) => {

  // }

  const [valSearch, setValSearch] = useState("");

  const getValue = (val) => {
    setValSearch(val?.id);
    // handleFetchMyTask(val?.id);
    console.log("PROPS: ", val?.id);
  };

  const [id, setId] = useState("");

  const handleuserId = (val) => {
    setId(val);
  };

  const resetTask = () => {
    getUserList();
    setId("");
  };
  return (
    <>
      <MetaTitle title={`Master Task Board`} />
      {currentUser ? (
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="master_task_page">
              <FilterHeader
                addTaskBtn="false"
                assignTaskBtn="false"
                getValue={getValue}
                masterTask={true}
                id={id}
                resetTask={resetTask}
              />

              <div className="task_container">
                <div className="task_card user_list_card">
                  <div className="task_body user_list_body">
                    <MasterUserList
                      userLists={userLists}
                      initialUserId={false}
                      valSearch={valSearch}
                      handleuserId={handleuserId}
                      // handleLoad={handleLoad}
                    />
                  </div>
                </div>
                <Outlet />
              </div>
            </div>
          )}
        </>
      ) : (
        <h1 className="text-center py-4 text-capitalize d-flex align-items-center justify-content-center">
          user not authenticated please login first
          {/* loading... */}
        </h1>
      )}
    </>
  );
};

export default MasterTask;
