// import React, { useEffect } from 'react'
import React from "react";
import "./taskManager.scss";
import TaskHeader from "../../components/taskManager/TaskHeader";
// import { Outlet, useNavigate } from 'react-router-dom';
import { Outlet } from "react-router-dom";
// import MyTask from './myTask/MyTask';
// import { Routes } from 'react-router-dom';

const TaskManager = () => {
  // const navigate = useNavigate();

  // const goToMyTaskPage = () => {
  //   navigate("my-task")
  // }

  // useEffect(() => {
  //   function goToMyTaskPage(){
  //     navigate("my-task")
  //   }
  //   goToMyTaskPage();
  // }, [])

  return (
    <div className="page taskManager_page">
      <div className="page_header_row max_container">
        <div className="page_heading">
          <h2>Task Manager</h2>
          <p>Here is a list of task that you have created</p>
        </div>
      </div>

      {/* ========== task header =========== */}
      <TaskHeader />

      {/* ============ taskManager_container ============== */}
      <div className="taskManager_container max_container">
        <Outlet />
      </div>
    </div>
  );
};

export default TaskManager;
