import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/7_rocks_logo.png";
import { loginSchema } from "../../formSchemas";
import { useFormik } from "formik";
import "./login.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  loginFailure,
  loginStart,
  loginSuccess,
} from "../../reduxStore/userSlice";
import axios from "../../config/axiosinstance";
import { toast } from "react-hot-toast";
import CustomLoader from "../../components/common/Loader/CustomLoader";
const Login = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const currentUser = useSelector((state) => state.user.currentUser);

  // const isLoading = useSelector(state => state.user.isLoading)
  // console.log("isLoading :-", isLoading)
  // goToMyTaskPage();

  useEffect(() => {
    const goToMyTaskPage = () => {
      navigate("/taskManager");
    };

    if (currentUser) {
      goToMyTaskPage();
    }
    // eslint-disable-next-line
  }, [currentUser]);

  const initialLoginValues = {
    username: "",
    password: "",
  };

  const [isLoading, setIsLoading] = useState(false);

  //   ==== form submit =====
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    // resetForm,
  } = useFormik({
    initialValues: initialLoginValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      console.log("values :-", values);

      setIsLoading(true);
      dispatch(loginStart());

      const body = {
        service_name: "login",
        username: values.username,
        password: values.password,
      };

      console.log(body);

      try {
        const resp = await axios.post("/timesheet-web-api", body);
        console.log("<<< resp >>>", resp);
        if (resp.data.status === 0) {
          setIsLoading(false);
          toast.error(resp.data.msg);
        }
        // setRespMsg('')
        else {
          dispatch(loginSuccess(resp.data));
          setIsLoading(false);
          navigate("/taskManager");
        }
      } catch (err) {
        // setUserAuth(false)
        dispatch(loginFailure());
        setIsLoading(false);
        toast.error("error :-", err);
      }
    },
  });

  return (
    <div className="login_page">
      <div className="login_box">
        <div className="logo">
          <picture>
            <img src={logo} alt="logo" />
          </picture>
          <h2>Welcome!</h2>
          <p>Please enter your log in details below......</p>
        </div>

        <div className="login_form">
          <form onSubmit={handleSubmit}>
            <div className="custom_input_field">
              <label>Enter Username</label>
              <input
                type="text"
                autoComplete="off"
                name="username"
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
                id="username"
                className="input_field"
                placeholder="Enter Username"
              />

              {/* ===== error msg ===== */}
              {errors.username && touched.username ? (
                <span className="text-danger form_error">
                  {errors.username}
                </span>
              ) : null}
            </div>

            <div className="custom_input_field">
              <label>Enter Password</label>
              <input
                type="password"
                autoComplete="off"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                id="password"
                className="input_field"
                placeholder="Enter Password"
              />

              {/* ===== error msg ===== */}
              {errors.password && touched.password ? (
                <span className="text-danger form_error">
                  {errors.password}
                </span>
              ) : null}
            </div>

            <button className="submit_btn" type="submit">
              {isLoading ? <CustomLoader /> : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
