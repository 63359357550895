import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../config/axiosinstance";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import PendingTodoRecords from "../table/PendingTodoRecords";
import InProgressRecords from "../table/InProgressRecords";
import AnimatedLoader from "../common/Loader/AnimatedLoader";

const PendingTaskActivity = () => {
  // useEffect(() => {
  //     setPendingTasks(JSON.parse(localStorage.getItem("pendingTasks")))
  // }, [])

  const currentUser = useSelector((state) => state.user.currentUser);
  // console.log("currentUser in user list :-", currentUser)
  // const [deadlineRequestBtn, setDeadlineRequestBtn] = useState(false);

  let params = useParams();
  // console.log("params >>> :-", params)
  const userId = params.id;

  const assignedTaskBoard = params.master;
  // console.log("userId :-", userId)

  const [pendingTasks, setPendingTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [masterUsers, setMasterUsers] = useState([]);

  const getMasterUserList = useCallback(async () => {
    if (currentUser) {
      try {
        const body = {
          service_name: "master_user_list",
          token: currentUser?.token,
          user_id: currentUser?.User?.id,
        };
        const resp = await axios.post("/timesheet-web-api", body);
        // console.log('Master users "-', resp?.data?.data)
        if (resp?.data?.status === 1) {
          setMasterUsers(resp?.data?.data);
        } else {
          toast.error(`Error :- ${resp?.data?.msg}`);
        }
      } catch (err) {
        toast.error(`Error :- ${err}`);
      }
    } else {
      toast.error("no users found...");
    }
  }, [currentUser]);

  useEffect(() => {
    getMasterUserList();
  }, [getMasterUserList]);

  const fetchMasterTask = useCallback(async () => {
    const userIdStr = userId.toString();
    setIsLoading(true);
    const body = {
      service_name: "my_task_activity_detail",
      token: currentUser.token,
      user_id: userId,
      // my_task_board:
      //   currentUser?.User?.id === userIdStr
      //     ? 1
      //     : masterUsers.some((user) => user.id === userIdStr)
      //     ? 1
      //     : 0,
    };

    if (
      assignedTaskBoard !== null &&
      assignedTaskBoard !== undefined &&
      assignedTaskBoard &&
      masterUsers.some((user) => user.id === userIdStr)
    ) {
      body.my_task_board = 0;
      body.logged_in_user = currentUser?.User?.id;
    } else if (
      currentUser?.User?.id === userIdStr ||
      masterUsers.some((user) => user.id === userIdStr)
    ) {
      body.my_task_board = 1;
    } else {
      body.my_task_board = 0;
      body.logged_in_user = currentUser?.User?.id;
    }
    // else if()
    // if (masterUsers.some((user) => user.id !== userId)) {
    //   body.logged_in_user = currentUser?.User?.id; // Adjust the condition and value as needed
    // }

    try {
      const resp = await axios.post("/timesheet-web-api", body);
      // console.log("master task resp :-", resp)
      if (resp?.data.status === 1) {
        setIsLoading(false);
        setPendingTasks(resp?.data);
        // setPendingTasks(resp?.data?.Inprogressrecords)
      } else {
        toast.error(`Error :- ${resp?.data?.msg}`);
      }
    } catch (err) {
      setIsLoading(false);
      console.log("taskList error :-", err);
    }
  }, [
    currentUser?.User?.id,
    currentUser.token,
    masterUsers,
    userId,
    assignedTaskBoard,
  ]);

  useEffect(() => {
    // setUserTasks(JSON.parse(localStorage.getItem("userTasks")))
    if (currentUser && masterUsers?.length > 0) {
      fetchMasterTask();
    } else if (currentUser) {
      fetchMasterTask();
    }
    // eslint-disable-next-line
  }, [userId, fetchMasterTask, currentUser, masterUsers]);

  // console.log("pending tasks :-", pendingTasks)

  //   console.log("CURRENT USER: ", currentUser?.User?.id);

  console.log("MASTER USER: ", masterUsers);

  return (
    <>
      {pendingTasks === null ? (
        <>
          <p className="text-danger">
            task are not available for this user id & user id is :-{" "}
            <b>{userId}</b>
          </p>
        </>
      ) : (
        <>
          {/* <MyTaskComponent tasks={pendingTasks} setTasks={pendingTasks} pendingTaskStatus={true}/> */}
          <div className="row w-100">
            <div className="col-12">
              <div className="card w-100">
                <div className="card-header">To-do records</div>
                <div className="card-body p-0">
                  {isLoading ? (
                    <AnimatedLoader />
                  ) : (
                    <>
                      {pendingTasks?.ToDOrecords && (
                        <PendingTodoRecords
                          ToDOrecords={pendingTasks.ToDOrecords}
                          isLoading={isLoading}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card w-100 mt-4">
                <div className="card-header">In-Progress</div>
                <div className="card-body p-0">
                  {isLoading ? (
                    <AnimatedLoader />
                  ) : (
                    <>
                      {pendingTasks?.Inprogressrecords && (
                        <InProgressRecords
                          inProgressRecords={pendingTasks.Inprogressrecords}
                          isLoading={isLoading}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PendingTaskActivity;
