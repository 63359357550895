import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data : null,
    isLoading : false,
    error : false,
}

export const taskSlice = createSlice({
    name : 'myTasks',
    initialState,
    reducers: {
        myTaskStart : (state) => {
            state.isLoading = true
        },
        myTaskSuccess : (state, action) => {
            state.isLoading = false
            state.data = action.payload
        },
        myTaskFailure : (state) => {
            state.isLoading = false
            state.error = true
        },
        clearMyTaskData : (state) => {
            // return initialState
            // ===== or =====
            state.data = null;
            state.isLoading = false;
            state.error = false;
        }
    }
})

export const { myTaskStart, myTaskSuccess, myTaskFailure , isLoading } = taskSlice.actions

export default taskSlice.reducer;