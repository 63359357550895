import React from "react";
import { Link } from "react-router-dom";

const ChatItem = (props) => {
  console.log(props);
  const getFileType = (url) => {
    const extension = url.split(".").pop().toLowerCase();
    if (extension === "png" || extension === "jpg" || extension === "jpeg") {
      return "image";
    } else if (extension === "pdf") {
      return "pdf";
    } else if (
      extension === "mp4" ||
      extension === "avi" ||
      extension === "mov"
    ) {
      return "video";
    } else if (
      extension === "mp3" ||
      extension === "ogg" ||
      extension === "wav"
    ) {
      return "audio";
    } else if (
      extension === "csv" ||
      extension === "txt" ||
      extension === "doc" ||
      extension === "docx" ||
      extension === "xls" ||
      extension === "xlsx"
    ) {
      return "doc";
    } else {
      return "unknown";
    }
  };

  const renderFile = () => {
    const fileType = getFileType(props.image);

    console.log(fileType, "filetype");

    switch (fileType) {
      case "image":
        return (
          <Link target="_blank" to={`${props.image}`}>
            <img
              src={props.image}
              onError={(e) =>
                (e.target.src =
                  "https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg")
              }
              alt=""
            />
          </Link>
        );
      case "pdf":
        return (
          <a href={`${props.image}`} target="_blank" rel="noopener noreferrer">
            PDF Document
          </a>
        );
      case "video":
        return (
          <video width="320" height="240" controls>
            <source src={`${props.image}`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );
      case "audio":
        return (
          <audio controls>
            <source src={`${props.image}`} type="audio/mpeg" />
            Your browser does not support the audio tag.
          </audio>
        );
      case "unknown":
        return <p></p>;
      case "doc":
        return (
          <div>
            <a
              href={`${props.image}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Open File
            </a>
          </div>
        );
      default:
        return (
          <div>
            File type not supported.{" "}
            <a
              href={`${props.image}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Open anyway
            </a>
          </div>
        );
    }
  };

  return (
    <div
      style={{ animationDelay: `0.8s` }}
      className={`chat_item  ${props.user ? props.user : ""}`}
    >
      <div className="chat_item_content">
        <div className="chat_msg">{props.msg}</div>
        <div className="img-content-chat-container">{renderFile()}</div>
      </div>
      <p className="created-chat-date">{props.created}</p>
    </div>
  );
};

export default ChatItem;
