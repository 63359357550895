import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { addTaskSchema } from "../../formSchemas";
import HTMLReactParser from "html-react-parser";
import Icon from "../../assets/icons/icon";
// import { v4 as uuidv4 } from 'uuid'
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
// import { useSelector } from 'react-redux'
import axios from "../../config/axiosinstance";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomLoader from "../common/Loader/CustomLoader";

const AddTask = ({
  closeModal,
  tasks,
  setTasks,
  addTaskSubmit,
  setAddTaskSubmit,
  handleLoad,
  SelfTask,
}) => {
  // userData
  const currentUser = useSelector((state) => state.user.currentUser);
  const [userAuth, setUserAuth] = useState(false);

  const [default_end_dTime, setDefault_end_dTime] = useState("");

  const fetchDefaultEndDate = useCallback(async () => {
    const body = {
      token: currentUser.token,
      service_name: "task_defult_end_date",
    };

    try {
      const resp = await axios.post("/timesheet-web-api", body);
      console.log("Default End Date: ", resp?.data.data);

      if (resp.data?.status === 1) {
        // toast.success("Task inserted successfully...😊");
        setDefault_end_dTime(
          new Date(resp?.data.data.end_date + " " + resp?.data?.data?.end_time)
        );
        console.log(
          "Default End Date: ",
          resp?.data.data.end_date + " " + resp?.data?.data?.end_time
        );
      }
    } catch (err) {
      console.log("Default End Time error :-", err);
    }
  }, [currentUser.token]);

  useEffect(() => {
    fetchDefaultEndDate();
  }, [fetchDefaultEndDate]);

  useEffect(() => {
    if (currentUser) {
      setUserAuth(true);
    }
    // eslint-disable-next-line
  }, []);

  const initialTaskValues = {
    // task_id : uuidv4(),
    // user_id : uuidv4(),
    title: "",
    details: "",
    end_date: "",
    // status: "todo"
    // status: "Pending"
    task_status: "Pending",
  };

  // console.log()
  // task_list api
  // const fetchMyTask = async () => {
  //     const body = {
  //         token: currentUser.token,
  //         assignee_user_id: currentUser.User.id,
  //         status: 'Pending',
  //         service_name: 'task_list'
  //     }
  //     try {
  //         const resp = await axios.post("/timesheet-web-api", body)
  //         console.log("task resp :-", resp)
  //         setTasks(resp.data?.data)
  //     } catch (err) {
  //         console.log("taskList error :-", err)
  //     }
  // }

  //   ==== form submit =====

  const [isLoading, setIsLoading] = useState(false);

  const [errMsg, setErrMsg] = useState("");

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: initialTaskValues,
    validationSchema: addTaskSchema,
    onSubmit: async (values) => {
      console.log("Current_Date_and_time: ", values.end_date);
      const formattedDate =
        values.end_date === ""
          ? formatDate(default_end_dTime)
          : formatDate(values.end_date);

      const enteredDateTimestamp = new Date(values.end_date).getTime();
      const currentTimestamp = new Date().getTime();

      if (enteredDateTimestamp <= currentTimestamp) {
        setErrMsg("Date & Time Should Be Greater Than Current Date & Time....");

        setTimeout(() => {
          setErrMsg("");
        }, 5000);

        return;
      }
      setIsLoading(true);
      // console.log(values)
      //   console.log("values :-", formattedDate);

      if (currentUser) {
        const body = {
          token: currentUser.token,
          service_name: "save_task",
          data: {
            assignee_user_id: currentUser.User.id,
            assigner_user_id: currentUser.User.id,
            created_by: currentUser.User.id,
            end_date: formattedDate,
            id: "",
            title: values.title,
            detail: values.details,
            reward_point_type_id: "1",
            reward_points: "1",
            strike_point_type_id: "1",
            strike_points: "1",
            status: "Pending",
            my_task: SelfTask ? 1 : 0,
          },
        };

        // console.log("body :-", body)

        try {
          const resp = await axios.post("/timesheet-web-api", body);
          // console.log("task resp :-", resp)

          // console.log("values :-", values)

          if (resp.data?.status === 1) {
            resetForm();
            closeModal(false);
            toast.success("Task Added Successfully...");
            setIsLoading(false);
            // addTaskSubmit(true)
            setAddTaskSubmit(true);
            handleLoad(true);
            // setTimeout(fetchMyTask, 2000)
          }
        } catch (err) {
          toast.error(err.message);
          setIsLoading(false);
        }
      } else {
        console.log("no users found...");
        setUserAuth(false);
      }
    },
  });

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;

  //   // Use a regular expression to allow only alphanumeric characters and spaces
  //   const sanitizedValue = value.replace(/[^a-zA-Z0-9\s]/g, "");

  //   handleChange({
  //     target: {
  //       name,
  //       value: sanitizedValue,
  //     },
  //   });
  // };

  //   console.log(formik)
  // console.log(errors)

  return (
    <div className="addTaskModal custom_modal">
      <div className="custom_modal_backdrop"></div>
      <div className="custom_modal_container">
        <div className="custom_modal_header">
          <h4>Add task</h4>
        </div>
        <div className="custom_modal_body">
          <form onSubmit={handleSubmit}>
            <div className="custom_input_field">
              <label>Enter Task Title</label>
              <input
                type="text"
                autoComplete="off"
                name="title"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                id="title"
                className="input_field"
              />

              {/* ===== error msg ===== */}
              {errors.title && touched.title ? (
                <span className="text-danger form_error">{errors.title}</span>
              ) : null}
            </div>
            <div className="custom_input_field">
              <label>Enter Task Details</label>
              <textarea
                rows={8}
                type="text"
                autoComplete="off"
                name="details"
                value={values.details}
                onChange={handleChange}
                onBlur={handleBlur}
                id="details"
                className="input_field"
              />

              {/* ===== error msg ===== */}
              {errors.details && touched.details ? (
                <span className="text-danger form_error">{errors.details}</span>
              ) : null}
            </div>
            {errMsg && (
              <p className="alert alert-danger err_msg_alert text-center alert-error">
                {errMsg}
              </p>
            )}
            {!SelfTask && (
              <div className="custom_input_field last_input_field">
                <label>Select Task End Date</label>
                {/* <input
              type="date"
              autoComplete="off"
              name="end_date"
              value={values.end_date}
              onChange={handleChange}
              onBlur={handleBlur}
              id="task_enddate"
              className="input_field date_input_field"
            /> */}

                {default_end_dTime !== "" && (
                  <DatePicker
                    autoComplete="off"
                    selected={values.end_date || new Date(default_end_dTime)}
                    onChange={(date) =>
                      handleChange({
                        target: { name: "end_date", value: date },
                      })
                    }
                    onBlur={handleBlur}
                    id="task_details"
                    minDate={new Date()}
                    showTimeInput
                    dateFormat="yyyy-MM-dd HH:mm:ss"
                    className="input_field date_input_field"
                  />
                )}
                <span className="icon">
                  {HTMLReactParser(Icon.date_input_icon)}
                </span>
                {/* ==== error msg ==== */}
                {errors.end_date && touched.end_date ? (
                  <span className="text-danger form_error">
                    {errors.end_date}
                  </span>
                ) : null}
              </div>
            )}

            <div className="custom_modal_footer">
              {userAuth ? (
                <>
                  <button className="cancel_btn" onClick={closeModal}>
                    cancel
                  </button>
                  <button className="submit_btn" type="submit">
                    {isLoading ? <CustomLoader /> : "Submit"}
                  </button>
                </>
              ) : (
                <p className="text-danger text-center">
                  user not authenticated
                </p>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddTask;
