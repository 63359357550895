import axios from "axios";

const instance = axios.create({
  // baseURL: 'https://master.sevenrocks.in/timesheet-web-api'
  // baseURL: "https://test-live-300923.sevenrocks.in/",
  baseURL: "https://erp.sevenrocks.in/",
  // baseURL: "https://staging.sevenrocks.in/",
});

export default instance;
// console.log(object)
