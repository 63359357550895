import React from 'react'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
  return (
    <div className='max_container p-5 text-center'>
      <h6 className='text-capitalize text-danger'>page not found</h6>
      <Link to='/' className='btn btn-sm mt-2 d-inline-block border-top-0 border-start-0 border-bottom-1 border-end-0 border-info'>Go home page</Link>
    </div>
  )
}

export default PageNotFound
