import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "../../config/axiosinstance";
import { toast } from "react-hot-toast";
import CustomLoader from "../common/Loader/CustomLoader";
const RequestExtensionModal = ({
  closeDeclineRequestModal,
  reqExtension,
  closeApproveRequestModal,
}) => {
  console.log("reqExtension :-", reqExtension);

  const currentUser = useSelector((state) => state.user.currentUser);

  const [errMsg, setErrMsg] = useState("");

  const [approveLoading, setApproveLoading] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);

  const approveRequest = async () => {
    setApproveLoading(true);
    const body = {
      service_name: "task_date_extension_approve",
      token: currentUser?.token,
      data: {
        task_id: reqExtension?.data?.task_id,
        request_extension_id: reqExtension?.data?.id,
        approved_by: currentUser?.User.id,
        approved_remarks: "",
        approve_end_date: reqExtension?.data?.request_end_date,
      },
    };

    try {
      const resp = await axios.post("/timesheet-web-api", body);
      // console.log("approve request task resp :-", resp)
      const serverResp = resp?.data;
      console.log("<<<< serverResp >>>>>", serverResp);
      if (serverResp.status === 1) {
        closeApproveRequestModal();
        toast.success(serverResp.msg);
        setApproveLoading(false);
      } else {
        toast.error(serverResp.msg);
        setErrMsg(serverResp.msg);
        setApproveLoading(false);
      }
    } catch (err) {
      console.log("error :-", err);
      setApproveLoading(false);
    }
  };

  const declineRequest = async () => {
    setDeclineLoading(true);
    const body = {
      service_name: "task_date_extension_decline",
      token: currentUser?.token,
      data: {
        task_id: reqExtension?.data?.task_id,
        request_extension_id: reqExtension?.data?.id,
        approved_by: currentUser?.User.id,
        approved_remarks: "",
        approve_end_date: reqExtension?.data?.approve_end_date,
      },
    };
    try {
      const resp = await axios.post("/timesheet-web-api", body);
      console.log("approve request task resp :-", resp);
      const serverResp = resp?.data;
      console.log("<<<< serverResp >>>>>", serverResp);
      if (serverResp.status === 1) {
        closeDeclineRequestModal();
        toast.success(serverResp.msg);
        setDeclineLoading(false);
      } else {
        toast.error(serverResp.msg);
        setErrMsg(serverResp.msg);
        setDeclineLoading(false);
      }
    } catch (err) {
      console.log("error :-", err);
      setDeclineLoading(false);
    }
  };

  const requestSubmit = () => {
    const req = reqExtension.title;
    if (req === "approve") {
      // console.log("<<<<<< approve request >>>>>>>>>>>>", req)
      approveRequest();
    } else if (req === "decline") {
      // console.log("<<<<<< decline request >>>>>>>>>>>>", req)
      declineRequest();
    } else {
      toast.error("Something went wrong...");
    }
  };

  console.log("Decline Data: ", reqExtension);

  return (
    <div className="custom_modal approve_req_modal">
      <div className="custom_modal_backdrop"></div>
      <div className="custom_modal_container">
        <div className="custom_modal_header">
          <h4 className="text-dark">
            Task ID -{" "}
            <span className="text-danger ms-2">{reqExtension.taskId}</span>
          </h4>
        </div>
        <div className="custom_modal_body">
          <h4>{reqExtension.title}</h4>
          <p>{reqExtension.msg}</p>
          {errMsg && (
            <p className="alert alert-danger err_msg_alert">{errMsg}</p>
          )}
        </div>
        <div className="custom_modal_footer">
          {closeApproveRequestModal ? (
            <button className="cancel_btn" onClick={closeApproveRequestModal}>
              No
            </button>
          ) : (
            <button className="cancel_btn" onClick={closeDeclineRequestModal}>
              No
            </button>
          )}
          {errMsg ? (
            <button
              className="cancel_btn secondary"
              onClick={
                closeDeclineRequestModal
                  ? closeDeclineRequestModal
                  : closeApproveRequestModal
              }
            >
              Cancel
            </button>
          ) : (
            <button
              className="submit_btn"
              type="submit"
              onClick={requestSubmit}
            >
              {approveLoading || declineLoading ? <CustomLoader /> : "Yes"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default RequestExtensionModal;
