import React, { useState } from "react";
import Icons from "../../assets/icons/icon";
import HTMLReactParser from "html-react-parser";
import { toast } from "react-hot-toast";
import axios from "../../config/axiosinstance";
import { useDispatch, useSelector } from "react-redux";
import {
  myTaskFailure,
  myTaskStart,
  myTaskSuccess,
} from "../../reduxStore/taskSlice";
import CustomLoader from "../common/Loader/CustomLoader";

const DeleteTaskModal = ({
  closeModal,
  taskId,
  tasks,
  setTasks,
  handleLoad,
}) => {
  // console.log("deleteTaskId :-", taskId)

  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.user.currentUser);

  // const isLoading = useSelector(state => state.myTasks.isLoading)

  const [errMsg, setErrMsg] = useState("");

  const [loading, setLoading] = useState(false);

  const deleteTask = async (id) => {
    // console.log(id)
    // const filterTask = tasks.filter(t => t.task_id !== id)
    // console.log("filterTask :-", filterTask)
    // localStorage.setItem("myTasks", JSON.stringify(filterTask))
    // setTasks(filterTask)
    // toast.success(`Task id ${id} is deleted successfully...`, {icon : "😊"})
    // closeModal(false);
    if (id) {
      setLoading(true);
      const body = {
        service_name: "task_undo_delete",
        token: currentUser?.token,
        task_id: id,
        assigner_user_id: currentUser?.User?.id,
        request_type: "Delete",
      };
      try {
        const resp = await axios.post("/timesheet-web-api", body);
        // console.log("approve request task resp :-", resp)
        const serverResp = resp?.data;
        // console.log("<<<< serverResp >>>>>", serverResp)
        if (serverResp.status === 1) {
          handleFetchMyTask();
          closeModal(false);
          toast.success(
            `Task Id - ${serverResp.task_id} Is Deleted Successfully`
          );
          handleLoad(true);
          setLoading(false);
        } else {
          // toast.error(serverResp.msg)
          setErrMsg(serverResp.msg);
          setLoading(false);
        }
      } catch (err) {
        console.log("server error :-", err);
        setErrMsg("Error :-", err);
        setLoading(false);
      }
    }
  };

  const handleFetchMyTask = async () => {
    dispatch(myTaskStart());
    const body = {
      token: currentUser?.token,
      assignee_user_id: currentUser?.User?.id,
      status: "All", //Pending
      service_name: "task_list",
    };
    try {
      const resp = await axios.post("/timesheet-web-api", body);
      setTasks(resp.data?.data);
      dispatch(myTaskSuccess(resp.data?.data));
    } catch (err) {
      dispatch(myTaskFailure());
      console.log("taskList error :-", err);
      setErrMsg("myTask error :-", err);
    }
  };

  return (
    <div className="custom_modal delete_modal">
      <div className="custom_modal_backdrop"></div>
      <div className="custom_modal_container">
        <div className="custom_modal_header">
          <h4>
            task ID - <span className="text-danger ms-2">{taskId}</span>
          </h4>
        </div>
        <div className="custom_modal_body">
          <i>{HTMLReactParser(Icons.delete_icon)}</i>
          <h4>Delete Task</h4>
          {errMsg ? (
            <p className="alert alert-danger alert-sm mb-0">{errMsg}</p>
          ) : (
            <p>Are you sure do you really want to delete this record?</p>
          )}
        </div>
        <div className="custom_modal_footer">
          {errMsg ? (
            <button className="cancel_btn" onClick={closeModal}>
              ok
            </button>
          ) : (
            <>
              <button className="cancel_btn" onClick={closeModal}>
                cancel
              </button>
              <button
                className="submit_btn"
                type="submit"
                onClick={() => deleteTask(taskId)}
              >
                {loading ? <CustomLoader /> : "Submit"}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeleteTaskModal;
