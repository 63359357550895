import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { extensionTaskSchema } from "../../formSchemas";
import HTMLReactParser from "html-react-parser";
import Icon from "../../assets/icons/icon";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "../../config/axiosinstance";
import toast from "react-hot-toast";
import CustomLoader from "../common/Loader/CustomLoader";

const ExtensionTaskModal = ({ closeExtensionModal, taskId, endDate }) => {
  const currentUser = useSelector((state) => state.user.currentUser);

  const [userAuth, setUserAuth] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setUserAuth(true);
    }
    // eslint-disable-next-line
  }, []);

  const initialValues = {
    extend_reason: "",
    task_end_date: "",
    taskId: taskId,
    request_by: currentUser?.User?.id,
  };

  const [errMsg, setErrMsg] = useState("");

  const [loading, setLoading] = useState(false);

  //   ==== form submit =====
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: extensionTaskSchema,
    onSubmit: async (values) => {
      if (new Date(values.task_end_date) <= new Date(endDate)) {
        // toast.error("Date Should Be Greater Than End Date....");
        setErrMsg("Date Should Be Greater Than End Date....");
        setTimeout(() => {
          setErrMsg("");
        }, 5000);
        return;
      }

      setLoading(true);
      const formattedDate = formatDate(values.task_end_date);
      console.log("ALL Data: ", {
        ...values,
        task_end_date: formattedDate,
        taskId,
      });

      if (currentUser) {
        const body = {
          token: currentUser.token,
          service_name: "task_request_for_extension",
          data: {
            task_id: taskId,
            type: "",
            request_by: currentUser?.User?.id,
            reason: values.extend_reason,
            request_end_date: formattedDate,
          },
        };

        try {
          const resp = await axios.post("/timesheet-web-api", body);

          console.log("Task Extension: ", resp);
          if (resp.data?.status === 1) {
            resetForm();
            closeExtensionModal(false);
            toast.success("Request Sent Successfully");
            setLoading(false);
          } else if (resp?.data?.status === 0) {
            // toast.error(resp?.data?.msg);
            setErrMsg(resp?.data?.msg);
            setTimeout(() => {
              setErrMsg("");
            }, 5000);
            setLoading(false);
          }
        } catch (err) {
          console.log("save task error :-", err);
          setLoading(false);
        }
      } else {
        console.log("no users found...");
        setUserAuth(false);
        setLoading(false);
      }
    },
  });

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  console.log(new Date(endDate));

  return (
    <div className="custom_modal">
      <div className="custom_modal_backdrop"></div>
      <div className="custom_modal_container" style={{ overflowY: "initial" }}>
        <div className="custom_modal_header">
          <h4>request for deadline extention</h4>
          <h6 className="text-dark text-uppercase mt-1">
            task ID - <span className="text-danger ms-2">{taskId}</span>
          </h6>
        </div>
        <div className="custom_modal_body">
          <form onSubmit={handleSubmit}>
            <div className="custom_input_field">
              <label>Task Extend Request Reason</label>
              <textarea
                rows={6}
                type="text"
                autoComplete="off"
                name="extend_reason"
                value={values.extend_reason}
                onChange={handleChange}
                onBlur={handleBlur}
                id=""
                className="input_field"
              />

              {/* ===== error msg ===== */}
              {errors.extend_reason && touched.extend_reason ? (
                <span className="text-danger form_error">
                  {errors.extend_reason}
                </span>
              ) : null}
            </div>
            {/* <div className="custom_input_field last_input_field">
                        <label>enter Task End Date</label>
                        <input type="date" autoComplete='off' name='task_end_date' value={values.task_end_date} onChange={handleChange} onBlur={handleBlur} id="task_details" className='input_field date_input_field'/>
                        <span className='icon'>{HTMLReactParser(Icon.date_input_icon)}</span>
                        {
                            errors.task_end_date && touched.task_end_date ? (
                                <span className='text-danger form_error'>{errors.task_end_date}</span>
                            ) : null 
                        }
                    </div> */}

            {errMsg && (
              <p className="alert alert-danger err_msg_alert text-center alert-error">
                {errMsg}
              </p>
            )}
            <div className="custom_input_field last_input_field">
              <label>enter Task End Date</label>
              <DatePicker
                autoComplete="off"
                selected={values.task_end_date || null}
                onChange={(date) =>
                  handleChange({
                    target: { name: "task_end_date", value: date },
                  })
                }
                minDate={new Date(endDate)}
                onBlur={handleBlur}
                id="task_details"
                showTimeInput
                popperPlacement="top"
                dateFormat="yyyy-MM-dd HH:mm:ss"
                className="input_field date_input_field"
              />

              <span className="icon">
                {HTMLReactParser(Icon.date_input_icon)}
              </span>
              {/* ==== error msg ==== */}
              {errors.task_end_date && touched.task_end_date ? (
                <span className="text-danger form_error">
                  {errors.task_end_date}
                </span>
              ) : null}
            </div>
            <div className="custom_modal_footer">
              {userAuth ? (
                <>
                  <button className="cancel_btn" onClick={closeExtensionModal}>
                    cancel
                  </button>
                  <button className="submit_btn" type="submit">
                    {loading ? <CustomLoader /> : "Submit"}
                  </button>
                </>
              ) : (
                <p className="text-danger text-center">
                  user not authenticated
                </p>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ExtensionTaskModal;
