import React, { useEffect, useState } from "react";
import "../taskManager.scss";
import FilterHeader from "../../../components/taskManager/FilterHeader";
import MyTaskComponent from "../../../components/taskManager/MyTaskComponent";

import axios from "../../../config/axiosinstance";
import { useSelector } from "react-redux";
import Loader from "../../../components/common/Loader/Loader";
import MetaTitle from "../../../components/MetaTitle/MetaTitle";

const SelfTask = () => {
  // const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.user.currentUser);
  // console.log("currentUser in myTask :-", currentUser)

  // const isLoading = useSelector(state => state.myTasks.isLoading)
  // const isLoading = useSelector(state => state.user.isLoading)

  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // console.log("my tasks :-", tasks)

  // console.log("isLoading :-", isLoading)

  // task_list api no use
  const handleFetchMyTask = async () => {
    setIsLoading(true);
    if (currentUser) {
      const body = {
        token: currentUser.token,
        assignee_user_id: currentUser.User.id,
        status: "All", //Pending
        service_name: "myself_task_list",
        // page : "1",
        // limit : "10"
      };

      // console.log("myTask body :-", body)

      // setIsLoading(true)
      // dispatch(myTaskStart())
      try {
        const resp = await axios.post("/timesheet-web-api", body);
        // console.log("task resp :-", resp)
        // setIsLoading(false)
        if (resp?.data.status === 1) {
          setIsLoading(false);
          setTasks(resp.data?.data);
          // dispatch(myTaskSuccess(resp.data?.data))
        }
      } catch (err) {
        setIsLoading(false);
        console.log("taskList error :-", err);
        // dispatch(myTaskFailure())
      }
    } else {
      console.log("no users found...");
    }
  };

  // console.log("tasks :-", tasks)

  useEffect(() => {
    handleFetchMyTask();
    // eslint-disable-next-line
  }, [currentUser]);

  const [addTaskSubmit, setAddTaskSubmit] = useState(false);
  // console.log("<<< addTaskSubmit >>>", addTaskSubmit)

  const handleLoad = (val) => {
    if (val) {
      // toast.success("Main Component...");
      handleFetchMyTask();
    }
  };

  const [valSearch, setValSearch] = useState("");

  const getValue = (val) => {
    setValSearch(val?.id);
    // handleFetchMyTask(val?.id);
    console.log("PROPS: ", val?.id);
  };

  const resetTask = () => {
    handleFetchMyTask();
  };

  return (
    <>
      <MetaTitle title={"Self Task Board"} />
      {currentUser ? (
        <>
          {isLoading ? (
            // <h1 className='text-center py-4 text-sm'>Loading...</h1>
            <Loader />
          ) : (
            <>
              <FilterHeader
                addTaskBtn="true"
                tasks={tasks}
                setTasks={setTasks}
                addTaskSubmit={addTaskSubmit}
                setAddTaskSubmit={setAddTaskSubmit}
                handleLoad={handleLoad}
                SelfTask={true}
                getValue={getValue}
                resetTask={resetTask}
              />
              {/* <MyTaskComponent tasks={tasks} setTasks={setTasks}/> */}
              <MyTaskComponent
                SelfTask={true}
                handleLoad={handleLoad}
                addTaskSubmit={addTaskSubmit}
                valSearch={valSearch}
              />
            </>
          )}
        </>
      ) : (
        <>
          <h1 className="text-center py-4 text-capitalize d-flex align-items-center justify-content-center">
            user not authenticated please login first
          </h1>
        </>
      )}

      {/* <MyTaskComponent tasks={tasks} setTasks={setTasks}/> */}
    </>
  );
};

export default SelfTask;
