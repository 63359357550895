import React, { createRef, useEffect, useState } from "react";
import ChatItem from "./ChatItem";
import HTMLReactParser from "html-react-parser";
import Icons from "../../assets/icons/icon";
import { useFormik } from "formik";
import { chatSchema } from "../../formSchemas";
import PreviewImage from "./PreviewImage";
import { useSelector } from "react-redux";
import axios from "../../config/axiosinstance";
import { DEFAULT_IMG } from "../../DefaultImg";
import toast from "react-hot-toast";
import AnimatedLoader from "../common/Loader/AnimatedLoader";
import ScrollToBottom from "react-scroll-to-bottom";

const ChatContent = ({ selectedTask }) => {
  // console.log("<<< selectedTask >>>", selectedTask)

  const currentUser = useSelector((state) => state.user.currentUser);

  const [chatItems, setChatItems] = useState([]);
  const [errMsg, setErrMsg] = useState("");

  const [chatLoading, setChatLoading] = useState(false);

  const fetchTaskComments = async () => {
    const body = {
      service_name: "task_comments",
      token: currentUser?.token,
      task_id: selectedTask?.id,
      // task_id : selectedTask?.id
    };
    setChatLoading(true);
    try {
      const resp = await axios.post("/timesheet-web-api", body);
      console.log("approve request task resp :-", resp);
      const serverResp = resp?.data;
      let reverse = serverResp?.data?.reverse();
      console.log("<<<< serverResp >>>>>", serverResp);
      if (serverResp.status === 1) {
        setChatItems(reverse);
        console.log("<<< chatItems >>>", chatItems);
        setChatLoading(false);
      } else {
        // toast.error(serverResp.msg)
        setErrMsg({
          msg: serverResp.msg,
          errors: serverResp.errors,
        });
        setChatLoading(false);
        console.log("server error :-", errMsg);
      }
    } catch (err) {
      setErrMsg(`Server error :- ${err}`);
      setChatLoading(false);
      console.log("server error :-", err);
    }
  };

  useEffect(() => {
    fetchTaskComments();
    // eslint-disable-next-line
  }, [currentUser]);

  const initialValues = {
    msg: "",
    file: null,
  };

  let messagesEndRef = createRef(null);

  // let chatItems_old = [
  //   {
  //     key: 1,
  //     type: "",
  //     msg: "Hi",
  //   },
  //   {
  //     key: 2,
  //     type: "",
  //     msg: "How are you ?",
  //   },
  //   {
  //     key: 3,
  //     type: "other",
  //     msg: "I am fine.",
  //   },
  //   {
  //     key: 4,
  //     type: "other",
  //     msg: "what about you",
  //   },
  //   {
  //     key: 5,
  //     type: "",
  //     msg: "awesome these days.",
  //   },
  //   {
  //     key: 6,
  //     type: "other",
  //     msg: "Today , whats the plan ?",
  //   },
  //   {
  //     key: 7,
  //     type: "other",
  //     msg: "what about you",
  //   },
  //   {
  //     key: 8,
  //     type: "",
  //     msg: "awesome these days.",
  //   },
  //   {
  //     key: 9,
  //     type: "other",
  //     msg: "Today , whats the plan ?",
  //   },
  //   {
  //     key: 10,
  //     type: "other",
  //     msg: "what about you",
  //   },
  //   {
  //     key: 11,
  //     type: "",
  //     msg: "awesome these days.",
  //   },
  //   {
  //     key: 12,
  //     type: "other",
  //     msg: "Today , whats the plan ?",
  //   },
  //   {
  //     key: 13,
  //     type: "other",
  //     msg: "what about you",
  //   },
  //   {
  //     key: 14,
  //     type: "",
  //     msg: "awesome these days.",
  //   },
  //   {
  //     key: 15,
  //     type: "other",
  //     msg: "Today , whats the plan ?",
  //   },
  //   {
  //     key: 16,
  //     type: "other",
  //     msg: "what about you",
  //   },
  //   {
  //     key: 17,
  //     type: "",
  //     msg: "awesome these days.",
  //   },
  //   {
  //     key: 18,
  //     type: "other",
  //     msg: "Today , whats the plan ?",
  //   },
  // ];

  const {
    values,
    isValid,
    dirty,
    handleBlur,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: chatSchema,
    onSubmit: async (values, action) => {
      if (
        values.file !== "" &&
        values.file !== null &&
        values.file !== undefined
      ) {
        console.log("values : ", values);
        const formData = new FormData();
        formData.append("file", values.file);

        try {
          const resp = await axios.post(
            "/CronJobs/ajaxUploadTempFile",
            formData
          );
          console.log("task resp :-", resp?.data?.data?.file);

          if (resp.data?.status === 1) {
            const body = {
              token: currentUser.token,
              service_name: "comment_save",
              data: {
                id: "",
                task_id: selectedTask?.id,
                user_id: currentUser?.User?.id,
                comment: values.msg,
                file_type: "1",
                file: resp?.data?.data?.path + resp?.data?.data?.filename,
                created_by: currentUser?.User?.id,
              },
            };

            try {
              const resp = await axios.post("/timesheet-web-api", body);
              console.log("task resp :-", resp);

              // console.log("values :-", values)

              if (resp.data?.status === 1) {
                action.resetForm();
                // closeAssignTaskModal(false);
                toast.success("Replied Succesfully...");
                fetchTaskComments();
              } else {
                toast.error(resp.data.msg);
                console.log("err :-", resp.data.msg);
              }
            } catch (err) {
              console.log("save task error :-", err);
              toast.error(err.message);
            }
          } else {
            toast.error(resp.data.msg);
            console.log("err :-", resp.data.msg);
          }
        } catch (err) {
          console.log("save task error :-", err);
          toast.error(err.message);
        }
        // resetForm({ values : ''});
        // action.resetForm();
      } else {
        const body = {
          token: currentUser.token,
          service_name: "comment_save",
          data: {
            id: "",
            task_id: selectedTask?.id,
            user_id: currentUser?.User?.id,
            comment: values.msg,
            file_type: "1",
            file: "",
            created_by: currentUser?.User?.id,
          },
        };
        try {
          const resp = await axios.post("/timesheet-web-api", body);
          console.log("task resp :-", resp);

          // console.log("values :-", values)

          if (resp.data?.status === 1) {
            action.resetForm();
            // closeAssignTaskModal(false);
            toast.success("Replied Succesfully...");
            fetchTaskComments();
          } else {
            toast.error(resp.data.msg);
            console.log("err :-", resp.data.msg);
          }
        } catch (err) {
          console.log("save task error :-", err);
          toast.error(err.message);
        }
      }
    },
  });

  const [attachFile, setAttachFile] = useState();

  // scrollToBottom = () => {
  //   messagesEndRef.current.scrollIntoView({behavior: "smooth"})
  // }

  console.log("Selected Task: ", attachFile);

  return (
    <div className="chat_content">
      <div className="chat_header">
        <div className="user_profile_container">
          <img
            src={selectedTask?.AssignerUser?.profile_image}
            onError={(e) => (e.target.src = DEFAULT_IMG)}
            alt=""
          />
          <div className="user_profile_details">
            <h4>
              {selectedTask?.AssignerUser?.firstname}{" "}
              {selectedTask?.AssignerUser?.lastname}
            </h4>
            <h6>frontend developer</h6>
          </div>
          <p>
            task ID -{" "}
            <span className="text-danger ms-2">{selectedTask?.id}</span>
          </p>
        </div>
      </div>
      {/* ==== chat_body ===== */}
      <div className="chat_item_body">
        {chatLoading ? (
          <AnimatedLoader />
        ) : (
          <ScrollToBottom className="chat_items">
            {!errMsg &&
              (chatItems.length > 0 ? (
                chatItems.map((item, index) => {
                  return (
                    <ChatItem
                      animationDelay={index + 2}
                      key={item.id}
                      // eslint-disable-next-line
                      user={item.type == 1 ? "me" : (item.type = "other")}
                      msg={item.comment}
                      image={item.file ? item.file : ""}
                      created={item.created}
                    />
                  );
                })
              ) : (
                <p className="text-center text-capitalize mt-4 pt-4 text-danger text-sm">
                  No Records Found
                </p>
              ))}
          </ScrollToBottom>
        )}

        {errMsg.errors && (
          <ul className="mt-4 pt-4">
            <li className="text-danger text-center text-capitalize ">
              {errMsg.msg}
            </li>
            {errMsg.errors.map((err) => {
              return (
                <li className="text-danger text-center text-capitalize ">
                  {err}
                </li>
              );
            })}
          </ul>
        )}

        <div ref={messagesEndRef}></div>
      </div>

      {/* ===== chat_footer ==== */}
      <div className="chat_footer">
        {values.file && <PreviewImage file={attachFile} />}
        <form onSubmit={handleSubmit} className="send_new_msg">
          <input
            type="text"
            placeholder="type here..."
            value={values.msg}
            name="msg"
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <input
            type="file"
            className="d-none"
            id="attach_file_input"
            name="file"
            onChange={(event) => {
              setFieldValue("file", event.target.files[0]);
              setAttachFile(event.target.files[0]);
            }}
          />
          <label for="attach_file_input" className="addFiles">
            {HTMLReactParser(Icons.attach_file_icon)}
          </label>

          <button
            type="submit"
            className="send_msg_btn"
            disabled={!(isValid && dirty)}
          >
            {HTMLReactParser(Icons.send_msg_btn_icon)}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatContent;
