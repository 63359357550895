import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import PendingTaskSidebar from "../../../components/taskManager/PendingTaskSidebar";
import FilterHeader from "../../../components/taskManager/FilterHeader";
import { useSelector } from "react-redux";
import axios from "../../../config/axiosinstance";
import { toast } from "react-hot-toast";
import Loader from "../../../components/common/Loader/Loader";
import MetaTitle from "../../../components/MetaTitle/MetaTitle";

const PendingTask = () => {
  const currentUser = useSelector((state) => state.user.currentUser);

  const [isLoading, setIsLoading] = useState(false);

  const [assignedUsers, setAssignedUsers] = useState([]);
  const [masterUsers, setMasterUsers] = useState([]);

  const getAssignUserList = async () => {
    setIsLoading(true);
    if (currentUser) {
      try {
        const body = {
          service_name: "assigned_user_list",
          token: currentUser?.token,
          user_id: currentUser?.User?.id,
        };
        const resp = await axios.post("/timesheet-web-api", body);
        // console.log('Assign users "-', resp?.data?.data)
        setIsLoading(false);
        if (resp?.data?.status === 1) {
          setAssignedUsers(resp?.data?.data);
        } else {
          toast.error(`Error :- ${resp?.data?.msg}`);
        }
      } catch (err) {
        toast.error(`Error :- ${err}`);
        setIsLoading(false);
      }
    } else {
      toast.error("no users found...");
      setIsLoading(false);
    }
  };

  const getMasterUserList = async () => {
    setIsLoading(true);
    if (currentUser) {
      try {
        const body = {
          service_name: "master_user_list",
          token: currentUser?.token,
          user_id: currentUser?.User?.id,
        };
        const resp = await axios.post("/timesheet-web-api", body);
        // console.log('Master users "-', resp?.data?.data)
        setIsLoading(false);
        if (resp?.data?.status === 1) {
          setMasterUsers(resp?.data?.data);
        } else {
          toast.error(`Error :- ${resp?.data?.msg}`);
        }
      } catch (err) {
        toast.error(`Error :- ${err}`);
        setIsLoading(false);
      }
    } else {
      toast.error("no users found...");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // getUserList()
    getAssignUserList();
    getMasterUserList();
    // eslint-disable-next-line
  }, [currentUser]);

  // const [initialUserId, setInitialUserId] = useState(true)

  return (
    <>
      <MetaTitle title={`Pending Task Activities`} />
      {currentUser ? (
        <>
          {isLoading ? (
            // <h1 className='text-center py-4 text-sm w-100'>Loading...</h1>
            <Loader />
          ) : (
            <div className="pendingTaskActivity_page">
              <FilterHeader
                hideSearch={true}
                hideDate={true}
                addTaskBtn="false"
                assignTaskBtn="false"
              />
              <div className="task_container">
                <div className="pending_task_container">
                  <PendingTaskSidebar
                    assignedUsers={assignedUsers}
                    masterUsers={masterUsers}
                    initialUserId={true}
                  />
                </div>
                <Outlet />
              </div>
            </div>
          )}
        </>
      ) : (
        <h1 className="text-center py-4 text-capitalize d-flex align-items-center justify-content-center">
          user not authenticated please login first
          {/* loading... */}
        </h1>
      )}
    </>
  );
};

export default PendingTask;
